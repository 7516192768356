/* CartProducts.scss */

.cart-product {
  margin: 5px 0;
  text-transform: capitalize;
  .ant-card .ant-card-body {
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
  }
}

.product-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px;
  border: 1px solid #e8e8e8;
}

.product-image {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.product-info {
  flex: 1;
  h4 {
    font-size: 17px;
    font-weight: 800;
  }
}

.product-details {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // margin-top: 5px;
}

.price-quantity {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  p {
    margin-right: 20px;
  }
}
.quantity-select {
  margin-bottom: 20px;
  margin-right: 20px;
}

.quantity-select {
  width: 80px;
  margin-top: 8px;
}

.action-buttons {
  // margin-top: 10px;
}

.move-to-wishlist {
  background-color: #172b4d;
  color: #fff;
  margin-bottom: 8px;
  border: none;
}
.move-to-wishlist:hover {
  background-color: #172b4d;
  color: #fff;
  margin-bottom: 8px;
  border: none;
}
.move-to-wishlist:focus {
  background-color: #172b4d;
  color: #fff;
  margin-bottom: 8px;
  border: none;
}
.remove-from-cart {
  background-color: #f5222d;
  color: #fff;
  border: none;
}

.product-info h3 {
  font-size: 20px;
  margin: 0;
  font-weight: 700;
}

.product-info p {
  font-size: 13px;
  color: #888;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--dark-blue);
  border-color: var(--dark-blue);
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-chectopbar-components-wrapperked:not(
    .ant-radio-button-wrapper-disabled
  ) {
  color: #fff;
  background: var(--dark-blue);
  border-color: var(--dark-blue);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--dark-blue);
}

.ant-radio-button-wrapper:first-child {
  border-right-color: var(--dark-blue);
  border-radius: 7px 0 0 7px !important;
}

.rupeeIcon {
  margin-top: 5px;
}

.price-details-button {
  position: absolute;
  top: -40px;
  right: 10px;
  background-color: var(--dark-blue);
  color: #fff !important;
  width: 280px;
  max-width: 350px;
  text-align: center;
}
.price-details-button:hover {
  background-color: var(--dark-blue);
  color: #fff !important;
}
