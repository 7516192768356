/* import fonts */
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;500;600;700;800&display=swap");
/* general styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sen", sans-serif !important;
}

html {
  scroll-behavior: smooth;
  font-family: "Sen", sans-serif !important;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  // color: rgba(0, 0, 0, 0.85);
}

p {
  font-size: 14px;
}

body {
  background: #f1f4f6 !important;
}

.ant-table {
  background: none;
  border-spacing: 0 3px !important;
  border-collapse: separate !important;
}
.ant-table-content {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.ant-table-content::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

input,
button,
select,
option,
textarea {
  font-family: inherit;
  outline-width: 0;
  border: 0;
}

main {
  float: right;
  //position: relative;
  position: absolute;
  right: 0;
  top: 0px;
  margin-left: 5px;
  padding: 65px 10px 10px 20px !important;
  height: 100vh;
  overflow-y: auto;
  width: calc(100% - 210px);
}

/* variables */
:root {
  --yellow: #ebff00;
  --white-font: #ffffff;
  --grey: #e5e5e5;
  --dark-blue: #172b4d;
  --student-blue: #172b4d;
  --brown: #684715;
  --dark-yellow: #cbaf1e;
  --orange: #db6d07;
  --normal-blue: #073abe;
  --pitch-black: #000000;
  --lemon-green: #96ad38;
  --pitch-green: #5f9e20;
  --grey-bg: hsl(0, 0%, 94%);
  --ash: #a9a29c;
  --neon-blue: #205364;
  --dark-green: #2a9728;
  --warm-red: #eb264a;
  --dark-red: #ff0000;
  --warm-blue: #172b4d;
  --sky-blue: #90c5f5;
  --warm-pink: #f8baba;
  --dark-purple: #710a6d;
  --blood-red: #aa0b28;
  --shadow: 7px 5px 6px 0 #ddd;
  --radius10: 10px;
  --radius5: 5px;
  --flex: flex;
  --blue-new: #172b4d;
  --hover-blue: #6a758d;
  --table-th: #f1f4f6;
  --border: #dbdbdb;
  --padding10: 10px;
  --padding5: 5px;
  --fc-border-color: #ddd;

  --dsh-purple: #7a70ba;
  --dsh-purple-light: #f1f0f8;
  --dsh-blue: #63b1dd;
  --dsh-blue-light: #ecf6fb;
  --dsh-brown: #d77748;
  --dsh-brown-light: #fbf1ec;
  --dsh-pink: #c95e9e;
  --dsh-pink-light: #f9eff5;

  --dsh-green: #80bd9e;
  --dsh-yellow: #f2cc8f;
  --dsh-brown: #e07a5f;
  --text-light: #808080;
}
ul {
  list-style-type: none;
}

.newCancelButton {
  background-color: var(--blood-red) !important;
  border: 1px solid var(--border) !important;
  text-align: center;
  color: var(--white-font) !important;
  min-width: 100px !important;
  margin-left: 10px;

  &:hover {
    background-color: var(--blood-red) !important;
    border: 1px solid var(--border) !important;
    color: var(--white-font) !important;
  }
  .ant-btn {
    color: white;
  }
}
a {
  text-decoration: none;
}

// * {
//   font-weight: 500;
// }

/* re-usable styles  */

.flex-sty {
  display: flex;
  align-items: center;
}

.flexBox-sty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-ct {
  text-align: center;
}
.grid-sty {
  display: grid;
  gap: 16px;
}
.app {
  height: 100vh;
  max-width: 1660px;
  margin: 0 auto;
}

/* .div-sty {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
} */

.div-sty {
  background: var(--white-font);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.white-text {
  font-weight: 400;
  color: var(--white-font);
}

#grey-bg {
  background: var(--grey);
}

#lightblue-bg {
  background: var(--lightblue);
}

#darkblue-bg {
  background: var(--dark-blue);
}

@media (max-width: 1200px) {
  main {
    flex: 1;
    width: calc(100% - 0px);
    float: right;
    position: relative;
    padding: 90px 16px 16px 16px;
  }
  .ant-select {
    margin-right: 0 !important;
  }
  .result {
    flex: 0.4 1;
    width: 100%;
  }
  .ant-table-content {
    overflow: auto;
  }
}

@media (max-width: 850px) {
  main {
    padding: 16px 16px 16px 16px;
  }
}
@media (max-width: 650px) {
  main {
    flex: 0;
    width: auto;
    float: initial;
    position: absolute;
    padding: 10px 0;
  }
  .ant-select {
    margin-right: 0;
  }
}

.blue-button {
  background-color: var(--dark-blue) !important;
  text-align: center;
  width: auto;
  color: var(--white-font) !important;
  border-radius: 5px;
}
.card {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

// .view-assignment {
//   .ant-table-tbody .ant-table-cell {
//     font-size: 13px;
//     font-weight: 500;
//     text-transform: capitalize;
//     padding: 3px !important;
//     box-shadow: rgba(0, 0, 0, 0.2) 0.2px 0.2px 0px;
//     cursor: pointer;
//     &:first-child {
//       border-radius: 8px 0 0 8px;
//     }

//     &:last-child {
//       border-radius: 0 8px 8px 0px;
//     }
//   }
// }

/*******Global CSS Start*****/
.loadinganimation {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  width: 130px;
  top: 40%;

  img {
    border-radius: 100px;
  }
  h3 {
    font-size: 14px;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 20px !important;
  text-align: center !important;
  .ant-btn-primary {
    background: var(--blue-new);
    border: none;
  }
}
.ant-modal-confirm-body {
  text-align: center !important;
}
.ant-modal-confirm-body > .anticon {
  float: none !important;
  margin-right: 16px;
  font-size: 31px !important;
}

.ant-select-lg {
  font-size: 14px !important;
}

h1 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.ant-form-item-label > label {
  height: 40px;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
  justify-content: center;
}
.buttonbottom {
  padding: 10px 0;
}

.ant-table-thead > tr > th {
  border: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px dashed var(--border) !important;
  border-radius: var(--radius5);
}

.ant-input {
  border: 1px dashed var(--border) !important;
  border-radius: var(--radius5);
  height: 40px;
}

.ant-btn {
  // border-radius: var(--radius5) !important;
  // background-color: var(--blue-new);
  // color: #fff !important;
  border: none;
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  gap: 5px 0;
}

.ant-modal-body {
  padding: 15px 15px !important;
}

.ant-modal-content {
  border-radius: 10px !important;
  padding: 0px;
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    background-color: var(--table-th) !important;
  }
}

.modelinput {
  .ant-row {
    margin: 0 !important;
    gap: 5px;
  }
}
.ant-modal .ant-modal-content {
  padding: 0px;
}
.ant-modal-title {
  padding: 13px;
}
.ant-table-column-title,
.ant-table-thead > tr > th {
  // text-align: left;
}

.ant-table-tbody .ant-table-cell {
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 5px 8px !important;
  box-shadow: none;
  border-bottom: 1px dashed var(--border) !important;
  cursor: pointer;
  // text-align: left !important;

  span {
    // text-align: left !important;
    font-weight: 400 !important;

    a {
      font-weight: 400 !important;
    }
  }
  .anticon {
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    padding: 0;

    svg {
      font-size: 16px;
    }
  }
}

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
  border: 1px dashed var(--border);
}

.ant-select-arrow {
  top: 55% !important;
}

.ant-select-single .ant-select-selector {
  height: 40px !important;

  .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 40px !important;
    transition: all 0.3s, visibility 0s;
    text-align: left;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    line-height: 40px;
  }
}

.ant-table-thead > tr > th {
  background: var(--dark-blue) !important;
  color: var(--white-font) !important;
  padding: 10px !important;
}

.ant-radio-button-wrapper {
  line-height: 40px !important;
  height: 40px !important;
  border: 1px dashed var(--border) !important;
}

.ant-picker {
  border: 1px dashed var(--border) !important;
  height: 40px;
}
.ant-upload .ant-upload-select {
  background-color: var(--dark-blue);
}
.ant-modal-body {
  .ant-row {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0px !important;
    padding-bottom: 10px;

    .ant-col {
      padding: 0 !important;
    }

    .float-label {
      position: relative;
      width: 95%;
    }
  }
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-table {
  padding: 10px !important;
  border-radius: 10px !important;
  background: var(--white-font) !important;
}

.flex-new {
  display: flex;
}
// :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
//   .ant-table-column-sorter-inner {
//   display: inline-flex;
//   flex-direction: column;
//   align-items: center;
//   color: #fff;
// }
.ant-progress .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  height: 13px;
}

// :where(.css-dev-only-do-not-override-p7e5j5).ant-btn {
//   font-size: 14px;
//   height: 40px;
//   padding: 4px 15px;
//   border-radius: 6px;
// }
// :where(.css-dev-only-do-not-override-p7e5j5).ant-select-single.ant-select-lg
//   .ant-select-selector {
//   font-size: 14px;
// }

.ant-table-wrapper .ant-table-thead > tr > th {
  text-align: left !important;
}
.ant-table-tbody .ant-table-cell {
  text-align: left !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-select-single {
  height: auto !important;
}
.ant-btn {
  height: 40px;
}
.cancel-button {
  background-color: var(--white-font) !important;
  border: 1px solid var(--blue-new) !important;
  color: var(--blue-new) !important;
  width: 100px;
  margin-left: 10px;
  height: 40px;
}
.submit-button {
  background-color: var(--dark-blue) !important;
  text-align: center;
  min-width: 100px;
  color: var(--white-font) !important;
  border-radius: 5px;
  height: 40px;
}

.ant-table-thead .ant-table-cell {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: var(--dark-blue);
  padding: 5px;

  &:hover {
    background-color: #172b4d;
  }

  &:first-child {
    border-radius: 8px 0 0 0px;
  }

  &:last-child {
    border-radius: 0 8px 0px 0px;
  }
}
.ant-table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  background: #f0f0f0;
  // background-color: var(--grey);
}

/*******Global CSS END*****/

/*************common css class **********/
.dropdown-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 0 0 10px 0;
}
.header-text {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 0 0;
}
/*******Global CSS END*****/
