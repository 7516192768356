.payslip-container {
  .payslip-filtes {
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .ant-list .ant-list-items {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .payslip-cards {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .payslip-card {
      width: 150px;
      height: 150px;
      p {
        font-size: 15px;
        font-weight: 600;
        line-height: 25px;
      }
      .file-wrapper {
        font-size: 40px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
