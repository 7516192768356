.stuentAttendace_Request {
  position: relative;
  top: 0;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 10px 0;
    align-items: center;
  }
  .header-text {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
  }
  .request-btn {
    position: absolute;
    top: -45px;
    right: 5px;
  }
}
