.bankdetails-container {
  padding-bottom: 0px;

  .ant-form-item {
    width: auto !important;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .ant-picker {
    background-color: var(--white-font);
    width: 100% !important;
    height: 42px;
    border-radius: 5px;
  }
  .ant-picker-input {
    background-color: var(--white-font);
    border-radius: 5px;
    text-align: end;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-select {
    margin: 0;
  }
  .ant-select-single .ant-select-selector {
    background-color: #fff;
    height: 40px;
    border-radius: 5px;
  }
  .ant-select-single .ant-select-selector:hover {
    border: 1px solid var(--grey-bg);
    outline: none;
  }
  .antd-row .ant-form-item-row {
    background-color: var(--grey-bg);
  }
  .ant-input {
    background-color: var(--white-font);
    border-radius: 5px;
    height: 42px;
  }
  .ant-select-single .ant-select-selector {
    text-align: left !important;
  }
  .bankdetails-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bankdetails-card {
    margin-top: 10px;
    margin-right: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: auto;
    margin-bottom: 30px;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
  }

  .blue-button {
    margin-top: 15px;
  }
}
