.createresult-wrapper {
  .result-container {
    .header-container {
      display: flex;
      // margin-bottom: 10px;
      .ant-btn {
        height: 34px;
        padding: 0 10px;
      }
    }

    .mainheader {
      display: flex;
      align-items: center;
      gap:10px;
    }

    .closebtn{
      background: var(--blood-red);
      color: var(--white-font);
      &:hover{
        color: var(--white-font);
      }
    }
    .resultright {
      display: flex;
      align-items: center;
      gap: 10px;
      h3 {
        font-size: 14px;
        background: var(--blue-new);
        color: var(--white-font);
        padding: 9px 15px;
        border-radius: 5px;
        font-weight: 400;
      }
    }
  }
}
