.topbar-components-wrapper {
  .topbar {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 0px;
    padding: 5px 15px 5px 20px;
    justify-content: space-between;
    display: flex;
    background: var(--white-font);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
  }

  .admin-sp {
    font-weight: 500;
    margin: 0 10px;
    text-transform: capitalize;
    font-size: 14px;
  }
  .topbar .exam-cirlce {
    background: var(--grey);
    color: var(--dark-blue);
    display: var(--flex);
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    button {
      width: 25px;
      height: 25px;
      border-radius: 100px;
      background: var(--dark-blue);
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  :where(.css-dev-only-do-not-override-p7e5j5).ant-btn.ant-btn-icon-only {
    width: 32px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    height: 32px;
  }
  .topbar-logo {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 15%;
    align-items: center;
    img {
      height: 45px;
      width: 45px;
      margin-right: 10px;
    }
    h4 {
      font-size: 16px;
    }
  }

  #instLogo {
    background: var(--dark-blue);
    width: 35px;
    height: 35px;
    border-radius: 100px;
    .css-2s90m6-MuiAvatar-root {
      background: none;
      padding: 0 !important;
      width: 35px;
      height: 32px;
      display: flex;
      align-items: center;
      font-size: 27px;
    }
  }

  .mail,
  .bell {
    // background-color: var(--grey-bg);
    margin: 0 5px;
    padding: 0;
    border-radius: 50%;
    img {
      width: 25px;
    }
    .anticon svg {
      display: inline-block;
      width: 25px;
    }
  }
  .topbar-search {
    width: 300px;
    padding: 12px 10px 12px 14px;
    margin-right: 20px;
    background-color: var(--grey-bg) !important;
    border-radius: 40px !important;
    display: flex;
    justify-content: space-between;
  }
  /*global search*/
  .ant-input {
    background-color: var(--grey);
    border: none;
    border-radius: 40px;
    height: 35px;
  }
  .ant-input-group-addon {
    background-color: var(--grey);
    width: 60px;
    border-radius: 40px;
  }
  .ant-input-search-button {
    background-color: var(--grey);
    border: none;
    outline: none;
  }
  .anticon-search {
    // background-color: var(--grey);
    // color: var(--pitch-black);
  }
  /*end global searchh*/
  .topbar-search input {
    flex: 1;
  }
  .result-data {
    flex: 0.6;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-top: 10px;
  }

  .result-dataTitle {
    border-radius: 10px 10px 0px 0px;
    padding: 5px 2px;
    font-size: 17px;
  }

  .result-number {
    text-align: center;
    margin: 20px auto 30px;
  }

  .result-numberTitle {
    font-size: 39px;
  }

  .item-detailIcon {
    font-size: 28px;
  }
  .logout_btn {
    color: white;
    background-color: rgb(155, 78, 78);
    font-weight: 500;
    width: 100px;
    padding: 10px;
    border-radius: 50px;
  }

  /*media queries */
  @media (max-width: 1024px) {
    .result-data {
      grid-template-columns: repeat(3, 1fr);
      padding: 10px;
    }
  }

  @media (max-width: 768px) {
    .results {
      flex-direction: column;
    }
    .result-data {
      grid-template-columns: repeat(3, 1fr);
      padding: 10px 20px;
    }
    .result {
      margin: 30px 20px 20px;
    }

    .topbar-logo,
    .topbar-search,
    .none,
    .admin-sp {
      display: flex;
    }
  }

  @media (max-width: 650px) {
    .topbar-components-wrapper .topbar {
      justify-content: initial;
      flex-direction: column;
      padding: 0;
    }
    .result-data {
      grid-template-columns: repeat(2, 1fr);
    }
    .topbar {
      justify-content: initial;
      flex-direction: column;
      padding: 0;
    }
    .topbar-search {
      width: 120px;
    }
    .topbar-logo {
      position: relative;
      left: 0;
    }
    .topbar-icons {
      left: 0;
    }
  }
}
.ant-popover .ant-popover-inner {
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 5px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 0px;
  width: 380px;
  height: 503px;
  margin-right: 10px;
  overflow-y: scroll;
  margin-top: 5px;
}

.ant-popover .ant-popover-title {
  min-width: 177px;
  margin-bottom: 8px;
  background-color: white;
  border-bottom: 0.5px solid rgb(206, 206, 206) !important;
  color: #4a4949;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
}

/* .ant-popover.ant-popover-title {
  background-color: #172b4d;
  color: #fff;
} */
body.darkmode main {
  color: white;
  background-color: black;
}

/* .ant-popover.ant-popover-title {
  background-color: #172b4d;
  color: #fff;
} */
