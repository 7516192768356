.categories-wrapper{
.first-item-container {
  display: flex;
  gap: 200px;
  align-items: center;
}
.book-total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.books {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 0px;
}

.second-item-container {
  margin-top: 0px;
}
.books-count-container {
  background-color: var(--dark-blue);
  width: 43.75px;
  height: 43.75px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
}
.books-count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
}
.ant-input-group .ant-input {
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 15px;
  color: var(--dark-blue);
  outline: none;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border: none;
  width: 100px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: var(--dark-blue);
}

.anticon-search {
  width: 35px;
  height: 34px;
  padding: 2px;
  padding-top: 2px;
  border-color:var(--dark-blue) ;
  background-color: var(--dark-blue);
}

.background-image {
  /* Existing background properties */
  background-image: linear-gradient(
      to bottom,
      rgba(61, 66, 93, 0.6),
      rgba(67, 82, 123, 0.6)
    ),
    url(../../../assets/images/bookcover.jpg);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
  h1 {
    font-size: 20px;
    // color: var(--white-font);
    font-weight: 900;
  }
}

.text-container {
  text-align: center;
  h1 {
    font-size: 18px;
  }
}

.cards-row {
  display: flex;
}
}