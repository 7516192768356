.leftmenu-block {
  // margin-top: 48px;
  flex: 0;
  background-color: var(--dark-blue);
  overflow-x: hidden;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  height: 100vh;
  border-radius: 0;
  z-index: 9;
  box-shadow: 0px 30px 16px 0px #000;
  .edpedia-logo {
    background-color: #172b4d;
    width: 100%;
    height: 60px;
  }
  svg {
    font-size: 18px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  a {
    color: var(--white-font);
  }

  .topbar-components-wrapper {
    .topbar {
      padding-left: 5%;
    }
  }

  .ant-menu-sub.ant-menu-inline {
    padding-left: 12px;
  }
  .ant-menu-submenu-arrow {
    color: var(--white-font);
  }
  .edpedia-logo-container {
    position: fixed;
    top: 0;
    background-color: #172b4d !important;
    padding: 0;
    text-align: center;
    z-index: 999;
  }
  .ant-menu-item {
    &:hover {
      background: var(--yellow);
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    &:hover {
      background: var(--hover-blue);
      color: var(--white-font);

      .anticon svg {
        color: var(--white-font);
      }

      a {
        color: var(--white-font);
      }

      .ant-menu-submenu-arrow {
        color: #000;
      }
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    color: var(--white-font);
    padding: 10px !important;
    margin: 10px 0;
    width: 100%;
    border-radius: 0;
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title:first-child {
      background: var(--hover-blue);

      .anticon svg {
        color: #000;
      }

      .ant-menu-title-content {
        color: var(--white-font);
      }

      .ant-menu-submenu-arrow {
        color: var(--white-font);
      }
    }
  }

  h1 {
    color: #fff;
    padding: 1px 0 0 5px;
    color: var(--yellow);
    font-size: 23px;
    margin: 0;
  }

  .leftmenu {
    background-color: var(--dark-blue);
    color: var(--yellow);
    margin-bottom: 40px;
    margin-top:50px;
  }

  .leftmenu:hover {
    color: var(--yellow);
  }

  .ant-menu-title-content a {
    color: var(--white-font);
  }

  .anticon svg {
    color: var(--white-font);

    margin: 0 10px 0 0;
  }

  .ant-menu-item {
    padding: 10px 20px;
  }

  .ant-menu-submenu-title {
    padding: 10px 0;
    cursor: pointer;
  }

  .ant-menu .ant-menu-item:hover {
    background-color: white;
    color: var(--dark-blue);
  }

  .ant-menu-submenu .ant-menu {
    background-color: var(--dark-blue);
  }

  .ant-menu-inline .ant-menu-item::after {
    border-right: 7px solid var(--yellow);
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-menu-item-selected {
    background: var(--hover-blue) !important;

    .anticon svg {
      color: var(--white-font);
    }

    .ant-menu-title-content {
      a {
        color: var(--white-font);
      }
    }

    .ant-menu-title-content:hover {
      a {
        color: var(--dark-blue);
      }
    }
  }

  .ant-menu-item-selected:hover {
    color: var(--dark-blue);
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    border-color: #172b4d;
    background: #172b4d;
    border: 1px solid var(--yellow);
  }

  .ant-menu-submenu-title {
    .ant-menu-sub {
      .ant-menu-item-selected {
        .anticon svg {
          color: var(--dark-blue);
        }
      }
    }
  }
  .topnavlogoBig {
    width: 199px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #172b4d;
  }
  .topnavlogoSmall {
    width: 79px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #172b4d;
    padding-left: 24px;
  }
  .navbtns {
    background: none;
    padding: 6px 0 0 10px;
    border: 1px solid var(--yellow);
    border-radius: 5px;
  }
}

@media (max-width: 1200px) {
  .leftmenu-block {
    border-radius: 0;
    position: relative;
    width: 100%;
    display: none;
  }
}
.custom-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.custom-icon1 {
  height: 20px;
  width: 18px;
  margin-right: 5px;
}
// main {
//   flex: 0.82 1;
//   float: right;
//   position: relative;
//   width: calc(100% - 210px);
//   padding: 70px 20px 50px 20px;
// }

body.collapsed main {
  width: calc(100% - 100px);
}
@media (max-width: 991px) {
  .ant-menu-horizontal {
    display: none;
  }

  .ant-menu-mobile {
    display: block;
  }
}

@media (min-width: 992px) {
  .ant-menu-horizontal {
    display: block;
  }

  .ant-menu-mobile {
    display: none;
  }
}

@media (max-width: 1200px) {
  main {
    width: calc(100%);
    padding: 80px 6px 4px 6px;
  }
}
