.orders-container {
  margin-top: 10px;
  margin-left: 10px;
  .order-header {
    display: flex;
    gap: 10px;
    .button {
      background-color: var(--dark-blue);
      color: white;
      border-radius: 5px;
    }
  }

  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .ant-card {
    margin-bottom: 20px;

    .ant-card-head {
      background-color: #1890ff;
      color: #fff;
    }

    .ant-card-head-title {
      color: #fff;
    }
  }

  .ant-typography {
    margin-bottom: 8px;
  }

  .ant-card-inner {
    background-color: #f5f5f5;
  }

  .ant-space-vertical {
    display: block;
  }
}
