.submitstudent-wrapper {
  .backbtn {
    margin-bottom: 10px;
  }
  .studendcard-main {
    margin: 10px;

    .student-card {
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      height: auto;
      border-radius: 10px;
      padding: 0px 10px 10px 10px;
      display: flex;
      flex-direction: column;
      gap: 13px;
      text-align: center;

      .studentname {
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        display: block;
        margin-top: 5%;
      }
      .studentscore {
      }
      .studentdate {
        font-size: 14px;

        cursor: pointer;
        display: block;
      }

      .studenttime {
        font-size: 14px;

        cursor: pointer;
        display: block;
      }

      .anticon {
        background: var(--dark-blue);
        color: #fff;
        width: 21px;
        height: 21px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        text-align: center;
        justify-content: center;
        padding: 0;
      }
      .edit-score {
      }
    }
  }

  .f-button {
    display: flex;
    align-items: center;
    justify-content: center;

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .anticon {
      }
    }
    .cancel-button {
      display: flex;
      align-items: center;
      border-radius: 5px;
      align-items: center;
      justify-content: space-evenly;
      .anticon {
        background: none;
        color: var(--dark-blue);
      }
    }
  }
  .student-card small {
    color: var(--white-font);
    padding: 5px 14px;
    font-size: 10px;
    background-color: var(--dsh-green);
    border-radius: 10px;
    font-weight: 500;
    width: 70px;
    margin: 0 auto;
    margin-top: 22px;
  }

  .view-doc {
    width: 100px;
    height: 30px;
  }

  .blue-card {
    background-color: blue;
  }

  .red-card {
    background-color: red;
  }

  .green-card {
    background-color: green;
  }

  .left-half {
    width: 50%;
    background-color: white;
    color: black;
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #172b4d;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    margin: 0 5px 0 0;
  }
  .ant-btn-primary:not(:disabled):hover {
    color: #fff;
    background-color: #172b4d;
  }

  .right-half {
    width: 50%;
    background-color: white;
    color: black;
  }
}
