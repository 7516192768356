.document-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2{
      font-size: 18px;
      font-weight: 600;
    }
  }
  .document-upload-div {
    background-color: #fff;
    border-radius: 5px;
    .document-card {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }
  .upload {
    width: 380px;
    background-color: var(--dark-blue);
    color: #fff;
  }
  .list-container {
    display: flex;
    margin-top: 10px;
    padding: 0 5px;
    flex-wrap: wrap;
    gap: 15px;
    .cards {
      position: relative;
      top: 0;
      width: 300px;
      height: 300px;
      background-color: var(--white-font);
      padding: 20px;
      // color: #fff;
      margin-bottom: 10px;
      border-radius: 5px;
      // overflow-y: scroll;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      text-transform: capitalize;
      .h1 {
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 10px;
      }
      .dec {
        font-size: 14px;
      }
      .footer {
        position: absolute;
        bottom: 10px;
        right: 10px;
        .ant-btn{
          background: var(--dark-blue);
          color: var(--white-font);
        }
      }
    }
  }
}
