.staff-profile {
  // padding-left: 10px;
  // padding-top: 10px;
  .user-profile {
    background-color: var(--white-font);
    width: 100%;
  }

  .profile-btns button {
    border-radius: 0 0 5px 5px !important;

    font-size: 16px;
  }
  .ant-row {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
    gap: 15px 0;
}
.ant-table{
  padding: 0!important;
}
  .assignments {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  .profile-avatar {
    padding: 10px auto;
  }
  .profile-editBtn button {
    margin-left: auto;
    background: var(--dark-blue);
    padding: 10px 25px;
    border-radius: 40px;
    color: var(--white-font);
    margin-right: 10px;
  }
  .profile-editBtn {
    padding: 20px 0 40px;
    margin: 0 auto;
    max-width: 750px;
  }
  .staffrightbutton{
    display: flex;
    gap:10px;
  }

  .profile-editIcon {
    color: var(--yellow) !important;
    font-size: 20px;
    margin-right: 15px;
  }

  /* document styles */
  .document {
    height: 60vh;
    justify-content: center;
    display: flex;
    padding-top: 50px;
  }

  div .document-btn {
    background: #f0f0f0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px 20px;
    margin-right: 10px;
  }
  .document-icon {
    margin-left: 20px;
  }
  /*profilr btn*/
  .exam-btns {
    display: flex;
    justify-content: start;
    padding-left: 20px;
  }
  .profile-btn {
    padding: 0px 20px 35px;
    cursor: pointer;
    font-size: 17px;
    border-radius: 0px 0px 10px 10px;
    margin-right: 0px;
  }
  .profile-btn:hover {
    background: var(--dark-blue);
    color: var(--white-font);
  }
  .profile-btn-active {
    background: var(--dark-blue);
    color: var(--white-font);
  }
  .profile-btn-active:hover {
    background: var(--dark-blue);
    color: var(--white-font);
  }

  .gender-btns button {
    padding: 10px 20px;
    margin-right: 10px;
    background: #f0f0f0;
    border-radius: 10px;
    opacity: 0.6;
  }
  .gender-btns button:nth-child(1) {
    color: var(--dark-blue);
  }
  .teaching-subBtns button,
  .teaching-Classbtns button,
  .work-Subjectbtns button {
    background: var(--dark-blue);
    border-radius: 7px;
    color: #ffffff;
    font-weight: 400;
    margin-right: 10px;
    padding: 15px 30px;
  }

  .teaching-Classbtns button {
    margin-right: 5px;
    padding: 15px;
  }
  .addMore {
    text-align: right;
    margin-top: 7px;
  }
  .addMore button {
    background: #f0f0f0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: #000000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
  }
  .profile-update-container {
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
  .update-button {
    margin-right: 30px;
    margin-top: 40px;
    color: #ffffff;
  }
  .update-enable {
    background-color: #0033cc;
    border-color: #0033cc;
  }
  .update-not-enable {
    background-color: #ebf0fa;
    border-color: #ebf0fa;
  }

  .radioparent{
    border-bottom: 1px dashed var(--border);
    display: flex;
    justify-content: space-between;
    padding:  0 0 10px 0;
    .mainradio{
      gap: 10px;
      
        .ant-radio-button-wrapper{
          background: var(--white-font);
          border: none!important;
          border-radius: 5px 5px 0 0!important;
          margin: 0 3px 0 0;
          &::before{
            border: none!important;
            width: 0;
          }
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          background: var(--blue-new);
          border: none!important;
          border-radius: 5px 5px 0 0!important;
        }
      }
}

  /* media queries */
  @media (max-width: 500px) {
    .profile-form,
    .history-item {
      flex-direction: column;
    }

    .profile-btns {
      flex-direction: column;
      margin: 0 10px;
    }

    .work-history-subjects,
    .work-performance {
      align-items: flex-start;
      margin-top: 10px;
    }
  }
}
