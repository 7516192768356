.subject {
  .ant-progress .ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 100px;
    height: 5px;
  }
  .header-text {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
  }
  .ant-progress-line {
    position: relative;
    width: 75%;
    font-size: 14px;
    margin-inline-end: 8px;
    margin-bottom: 8px;
  }
  .loading-spinner {
    margin-left: 50%;
    margin-top: 10%;
  }

  /* study  */
  .study-button {
    background: #6687dc;
    border-radius: 20px;
    color: #ffffff;
    padding: 10px 30px;
  }
}
