.fields-container {
  display: flex;
  gap: 10px;
}
.main-container {
  display: flex;
  justify-content: space-between;
}
.homework-main-wrapper {
  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    background-color: var(--dark-blue);
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
    background-color: var(--dark-blue);
  }
}