.staff-resign-container {
.resglef{
  display: flex;
  align-items: center;
  gap: 10px;
}
  .backbtn {
    text-align: left;

    .goback_button {
      color: #fff;
      border-color: var(--dark-blue);
      background: var(--dark-blue);
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      border-radius: 5px;
      margin-right: 10px;
      margin-left: 5px;
      &:hover {
        background: var(--dark-blue);
      }
    }
  }
  .resign-card {
    width: 100%;
  }
  .resign-header {
    display: flex;
    justify-content: space-between;
    
  }
  .full-details-button {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 5px;
    border-radius: 5px;
  }

  .full-details-button:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 5px;
    border-radius: 5px;
  }
  
  .Add_button {
    background-color: var(--dark-blue);
    color: var(--white-font);    
    border-radius: 5px;
  }
  .Add_button:focus {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
  .Add_button:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
}
