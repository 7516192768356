#dashboard {
  .header-text {
    margin-bottom: 15px;
  }

  .no-timetable {
    padding: 5px;
    margin-left: 100px;
    margin-top: 20px;
    color: var(--dark-blue);
  }
  .loading-spinner {
    margin-left: 50%;
    margin-top: 5;
  }

  .timetable-grid {
    padding: 0 0 20px 0;
    display: flex;
    gap: 20px;

    .timetable-griditem {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      cursor: pointer;
      width: 100%;
    }

    .timetable-griditem {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      cursor: pointer;
    }
    .timetable-griditem:nth-child(1) {
      border-left: 5px solid var(--dsh-green);
    }
    .timetable-griditem:nth-child(2) {
      border-left: 5px solid var(--dsh-yellow);
    }
    .timetable-griditem:nth-child(3) {
      border-left: 5px solid var(--dsh-brown);
    }

    .timetable-griditem small {
      justify-content: center;
    }
    .timetable-griditem span {
      margin-bottom: 5px;
      // background: var(--hover-blue);
      // color: #fff;
      padding: 0 10px;
      border-radius: 100px;
      font-size: 14px;
    }

    .table-class {
      padding: 10px 10px 0 10px;
      border-radius: 5px 5px 100px 0;
      display: flex;
      justify-content: space-between;
    }

    .table-content-center {
      padding: 10px;

      h1 {
        font-weight: 600;
        font-size: 18px;
        text-align: left;
      }
    }
    .table-attendance {
      border-radius: 0 0 5px 5px;
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row-reverse;
      justify-content: space-between;
      color: var(--text-light);
    }
  }

  .dashboard-grid {
    grid-template-columns: repeat(6, 1fr);
  }
  .ant-empty-description {
    color: #000000;
  }

  .timetable-icon {
    font-size: 40px;
    margin-right: 10px;
  }
  .dashboard-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .dashboard-attendance {
    font-size: 14px;
  }
  .Subject-data {
    margin-bottom: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffff;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }

  .dashboard-icon {
    margin-left: 10px;
    font-size: 20px;
  }

  #dashboard-small {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 0;
  }

  .dashboard-activites {
    .ant-empty {
      // margin-left: 500px;
      display: flex;
      margin: auto;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }
    // display: flex;
    // margin-top: 10px;
  }

  .dashboard-activity {
    background-color: var(--white-font);
    padding: var(--padding10);
    box-shadow: var(--shadow);
    border-radius: var(--radius10);
    width: 50%;
  }
  .teaching-subjects {
    background-color: var(--white-font);
    color: var(--dark-blue);
    width: 400px;
    text-align: center;
    padding: 10px;
  }
  .dashboard-rate {
    background-color: var(--dark-blue);
  }

  .sub-item {
    padding: 8px 10px;
  }

  #bg-white {
    background: #ffffff;
  }

  #bg-pink {
    background: #f8baba;
  }

  #bg-red {
    background: #ff0000;
  }

  .bg-color-2 {
    // background: lightseagreen;
    // color: #000000;
  }
  .bg-color-4 {
    // background-color: green;
    // color: #ffffff;
  }
  .bg-color-1 {
    // background-color: pink;
  }
  .bg-color-3 {
    // background: #ff0000;
    // color: #ffffff;
  }
  .bg-color-5 {
    // background-color: yellowgreen;
    // color: white;
  }

  .blink_me {
    animation: blinker 0.5s linear infinite;
    margin-left: 15px;
    font-weight: bold;
    // transform: rotate(45deg);
  }

  @keyframes blinker {
    0% {
      color: rgb(5, 5, 154);
    }
    30% {
      color: pink;
    }
    50% {
      color: rgb(41, 2, 80);
    }
    80% {
      color: #ff0000;
    }
    100% {
      color: orange;
    }
  }

  .dashboard-activity-item {
    padding: 5px 0;
    margin-bottom: 5px;
  }

  .dashboard-activity-item p {
    font-size: 14px;
  }

  .subject-img {
    width: 100px;
  }

  .dashboard-inbox {
    flex: 0.2;
    flex-direction: column;
    position: relative;
    padding: 10px 10px;
    margin-left: 10px;
  }

  .dashboard-inboxIcon {
    font-size: 80px;
    color: var(--dark-blue);
  }
  .row-div {
    background-color: red;
  }

  .dshbottom {
    display: flex;
    gap: 20px;
  }

  .dashboard-assignments {
    gap: 10px;
    background: var(--white-font);
    padding: var(--padding10);
    box-shadow: var(--shadow);
    border-radius: var(--radius10);
    width: 50%;
  }

  .h4 {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 5px;
  }

  .activitymain {
    display: flex;
    font-size: 13px;
    gap: 10px;
    align-items: center;
    .accontent {
      width: 80%;
      line-height: 17px;
    }

    .acdate {
      color: var(--dsh-blue);
    }
  }

  .bg-color-1 {
    .acnew {
      background-color: var(--dsh-brown);
      color: var(--white-font);
      padding: 3px 10px;
      border-radius: 5px;
    }
  }

  .bg-color-2 {
    .acnew {
      background-color: var(--dsh-blue);
      color: var(--white-font);
      padding: 3px 10px;
      border-radius: 5px;
    }
  }

  .bg-color-3 {
    .acnew {
      background-color: var(--dsh-green);
      color: var(--white-font);
      padding: 3px 10px;
      border-radius: 5px;
    }
  }

  .bg-color-4 {
    .acnew {
      background-color: var(--dsh-yellow);
      color: var(--white-font);
      padding: 3px 10px;
      border-radius: 5px;
    }
  }

  .bg-color-5 {
    .acnew {
      background-color: var(--dsh-pink);
      color: var(--white-font);
      padding: 3px 10px;
      border-radius: 5px;
    }
  }

  #assign-title {
    margin-bottom: 15px;
  }
  .time-table-text {
    color: var(--dark-blue);
  }

  .dashboard-subjects {
    background: var(--white-font);
    padding: var(--padding10);
    margin-bottom: 20px;
    border-radius: var(--radius10);
    box-shadow: var(--shadow);

    .flex-container {
      .subject-cards-container {
        display: flex;
        // flex-wrap: wrap;
        gap: 10px;
        // justify-content: space-evenly;

        .subject-cards:nth-child(1) {
          background: var(--dsh-purple-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-purple);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(2) {
          background: var(--dsh-blue-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-blue);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(3) {
          background: var(--dsh-brown-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-brown);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(4) {
          background: var(--dsh-pink-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-pink);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(5) {
          background: var(--dsh-pink-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-pink);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(6) {
          background: var(--dsh-pink-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-pink);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(7) {
          background: var(--dsh-pink-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-pink);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(8) {
          background: var(--dsh-brown-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-brown);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(9) {
          background: var(--dsh-purple-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-purple);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(10) {
          background: var(--dsh-blue-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-blue);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(11) {
          background: var(--dsh-brown-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-brown);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }

        .subject-cards:nth-child(12) {
          background: var(--dsh-pink-light);
          .ant-progress .ant-progress-inner {
            // background: var(--dsh-pink);
            .ant-progress-text {
              // color: var(--white-font) !important;
            }
          }
        }
      }
    }
  }

  .subject-cards {
    width: 50%;
    height: auto;
    color: var(--dark-blue);
    background-color: var(--white-font);
    border-radius: 5px;

    h4 {
      color: var(--dark-blue);
      font-size: 16px;
      margin: 0 0 4px 0;
    }

    .progress-div {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
    }
  }

  .dash-assignment-wrapper {
    display: flex;
    gap: 10px;

    .dashboard-assignment {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      padding: 10px 10px 10px 10px;
      width: 100%;
      align-items: baseline;
      justify-content: space-between;
      border-bottom: 1px dashed var(--border);
      background: var(--white-font);
      border-radius: 5px;

      .blue-button {
        height: auto;
      }
    }

    .dashboard-assignChaps {
      width: 200px;
      white-space: nowrap;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--dark-blue);
    }

    .dashboard-assignment > div {
      font-size: 12px;
    }

    .dashboard-inboxNumber {
      background: var(--dark-blue);
      height: 50px !important;
      width: 50px !important;
      position: absolute;
      top: 6%;
      margin-left: 40px;
      color: var(--white-font);
      border-radius: 5px;
    }

    #dashboard-members {
      color: #96ad38;
      font-size: 10px !important;
    }

    #dashboard-assignChaps {
      color: #000000;
      text-align: left;
    }

    #dashboard-assignDetails {
      color: #172b4d;
    }

    #dashboard-assignNum {
      color: var(--lemon-green);
    }
  }

  /* media queries */

  @media (max-width: 1200px) {
    .dashboard-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 768px) {
    .dashboard-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 500px) {
    .dashboard-grid {
      grid-template-columns: repeat(1, 1fr);
    }

    .dashboard-activites,
    .dashboard-assignment {
      flex-direction: column;
    }

    .dashboard-subjects {
      margin: 0px 0px 10px;
    }
    .ant-switch.ant-switch-checked {
      background-color: pink;
      color: yellow;
    }

    .dashboard-assignment > div {
      margin-bottom: 20px;
    }

    .dashboard-assignment > div:nth-child(1) {
      margin-top: 15px;
    }
  }

  .custom-switch {
    margin-right: 5px;
  }
  .switch-checked,
  .switch-checked:hover {
    background-color: rgb(129, 248, 129);
  }

  .switch-unchecked,
  .switch-unchecked:hover {
    background-color: #ccc;
  }
}

@media (max-width: 800px) {
  #dashboard {
    .subject-cards {
      width: 48%;
    }

    .dshbottom {
      flex-direction: column;
      .dashboard-assignments {
        width: 100%;
      }
    }
  }
}
