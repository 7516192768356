.login {
  width: 100%;
}
.login-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-img img {
  width: 600px;
}
.login-inputs {
  display: flex;
  flex-direction: column;
  background: var(--dark-blue);
  padding: 30px 50px 60px;
  border-radius: 20px;
  color: white !important;
  margin-left: 20px;
}
.login-inputs > h3 {
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.login-input {
  border: 1px solid var(--yellow);
  border-radius: 50px;
  margin-bottom: 20px;
  padding: 10px 0;
}
.login-input input {
  background: none;
  font-style: normal;
  border: 0;
  padding-left: 40px;
  color: #ffffff;
  flex: 1;
}
.login-input input::placeholder {
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  font-weight: 300;
}
.login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.login-btn button {
  background: #ffffff;
  border-radius: 50px;
  border: 0;
  padding: 15px 50px;
}
.input-widget {
  display: flex;
  justify-content: space-between;
}
.input-widget small {
  font-size: 9px;
  font-weight: 400;
}

/* small screems */
@media (max-width: 768px) {
  .login-flex {
    flex-direction: column;
  }
  .login-img {
    display: none;
  }
  .login-inputs {
    margin: 0 20px;
  }
}

/* small screems */
@media (max-width: 500px) {
  .input-widget small {
    font-size: 10px;
  }
}
