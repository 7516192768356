.holidayCard {
  text-transform: capitalize;
  border-radius: 5px;
  padding: 8px;
  width: 160px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 5px;
  color: var(--white-font);
  text-align: center;

  .h3-text {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
  }
}
