
.books-wrapper{
  
}
.book-total-container {
 
  display: flex;
  flex-direction: row;
  align-items: center;
  .ant-empty {
    margin-left: 500px;
    padding-top: 20px;
  }
}
.books-row {
  display: flex;
  flex-direction: row;
}
// .ant-empty {
//   margin-left: 500px;
// }

.books { 
font-weight: 600; 
  line-height: 52px;
  color: #000000;
  margin-bottom: 0px;
}
.book-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin: 10px 0 0 0;
}
.high2{
  border-top: 1px dashed var(--border);
  padding: 10px 0 0 0;
  margin-top: 15px;
  font-size: 16px;
  color: var(--dsh-brown);
}
.datesInfo{
  
  .centertext{
    text-align: center;
    border-top: 1px dashed var(--border);
    padding: 15px 0 0 0;
  }
  p{
    display: flex;
    flex-direction: column;
    margin: 8px 0 7px 0;
    span{
      font-weight: 700;
    }
  }
}
.card-container {

  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  text-align: center;
  border-radius: 10px;
  width: 18.5%; 
  text-transform: capitalize;
  padding: 15px;
  img.card-image {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
}
.self-container {
  background-color: var(--dark-blue);
  color: #fff;
}
.buttons-container {
  text-align: center;

  .later-button {
    background-color: orange;
    color: white;
    text-align: center;
    margin-bottom: 5px;
    width: 100%;
    border: none;
  }

  .rent-button {
    color: white!important;
    text-align: center;
    margin-bottom: 5px;
    text-align: center;
    margin-bottom: 5px;
    width: 100%;
    border: none;
  }
  .green-bg {
    background-color: var(--dsh-green);
  }
  .orange-bg {
    background-color: var(--dsh-yellow);
  }

  .request-button {
    background-color: orange;
    color: white;
    margin-bottom: 5px;
  }
}
// book view

.back-button {
  margin-top: 20px;
  background-color: #172b4d;
  color: white;
}

.book-view {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 15px;
  border-radius: 10px;
  margin-top: 70px;

  .book-details {
    display: flex;
    padding: 10px;
  }

  .data-view {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .book-image {
    width: 300px;
    height: 250px;
    border-radius: 10px;
    margin-right: 25px;
  }

  .book-title {
    color: var(--dark-blue);
    font-weight: bold;
  }

  .description-heading {
    text-decoration: underline;
  }

  .confirm-modal {
    .ant-modal-body {
      p {
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;

        &:first-child {
          margin-top: 0;
        }
      }

      span {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .modal-cancel-button {
    background-color: var(--blood-red);
    width: 100px;
  }

  .modal-save-button {
    background-color: var(--dark-blue);
    width: 100px !important;
  }
}
