/* work history styles */

.work-history {
  margin-top: 10px;
  flex-direction: column;
  padding-left: 25px; 
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 10px;
  padding: 0;
  text-align: center;
  border-radius: 10px;
  // background: var(--white-font);
  background: none;
  .work-history-item {
    position: relative; 
    background-color: var(--white-font);
    border-radius: 10px;
    padding:20px;
    margin:20px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    //   rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    box-shadow: none;
  }
  .work-history-item::before {
    content: "";
    position: absolute;
    height: 106px;
    width: 2px;
    border: 1px dashed var(--dsh-green);
    left: -1.6%;
  }
  .work-history-item::after {
    top: -115px;
    content: "";
    margin-right: 30px;
    position: absolute;
    height: 10px;
    width: 10px;
    background: var(--dsh-green);
    border-radius: 100%;
    outline-offset: 5px;
    outline: 1px solid var(--dsh-green);
    top: 0;
    left: -2%;
  }

  .history-item {
    display: flex;
    justify-content: space-between;
  }

  .work-history-headers,
  .work-history-subjects,
  .work-performance {
    flex: 0.3;
  }
  .work-performance span {
    margin-top: 10px;
    font-size: 15px;
    text-transform: capitalize;
    background: var(--dsh-green);
    padding: 9px 20px;
    color: var(--white-font);
    border-radius: 5px;
  }

  .work-performance span .rating-icon {
    font-size: 15px;
    margin-left: 6px;
  }

  .work-history-headers p {
    font-size: 14px;
    opacity: 1;
    text-transform: capitalize;
    color: var(--dsh-brown);
  }
  .work-history-headers h3 {
    font-weight: 600;
    font-size: 18px;
  }
  .work-history-headers h5 {
    font-weight: 500;
    padding: 7px 0 8px;
    font-size: 13px;
  }

  .work-history-subjects,
  .work-performance {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .work-Subjectbtns {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    Button {
     
      padding: 0 18px;
    text-transform: capitalize;

    }
  }

  .profile-edit {
    position: absolute;
    right: 50px;
    top: 150px;
  }
  .profile-edit Button {
    background-color: var(--dark-blue);
    color: #fff;
  }
}
.add-container{
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 10px;
  .addbutton{
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
}

.bg-container{
  background-color: var(--white-font);  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 120px;
  margin-top:10px;
  border-radius: 10px;
  box-shadow: var(--shadow);

}