.exam-container {

  /*profilr btn*/
  .profile-btn {
    padding: 0px 20px 35px;
    cursor: pointer;
    font-size: 17px;
    border-radius: 0px 0px 10px 10px;
    margin-right: 0px;
  }
  .loading-spinner {
    margin-left: 50%;
  }
  .profile-btn:hover {
    background: var(--dark-blue);
    color: var(--white-font);
  }
  .profile-btn-active {
    background: var(--dark-blue);
    color: var(--white-font);
  }
  .profile-btn-active:hover {
    background: var(--dark-blue);
    color: var(--white-font);
  }

  .add-examBtn {
    background: var(--dark-blue);
    padding: 23px 40px;
    border-radius: 5px;
    font-weight: 400;
    color: var(--white-font);
    margin-left: 5rem;
  }
  .add-examBtn:hover {
    background: var(--dark-blue);

    color: var(--white-font);
  }

  .result-timerText small {
    font-size: 9px;
    color: #4458be;
  }

  .result-timerImg {
    margin-left: 30px;
  }
  .result-content {
    display: flex;
    flex-wrap: wrap;
  }

  .result-circle {
    border-radius: 100%;
    height: 60px;
    width: 60px;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
  }
  .result-content-item {
    flex-direction: column;
    text-align: center;
  }
  .flex-center {
    width: 90%;
  }
  .previous-content-item {
    margin-top: 10px;
    padding: 5px 15px;
    margin-right: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
  .result-content-item small {
    padding-top: 4px;
  }

  /* upcoming */
  .upcoming {
    width: 100%;
    display: flex;
  }
  .previous {
    width: 100%;
  }
  .previous-btn-div {
    display: flex;
    justify-content: center;
  }
  .upcoming-btns {
    display: flex !important;
    flex-direction: row;
  }
  .upcoming-items {
    width: 100%;
  }
  .upcoming-card {
    padding: 5px 0px;
  }
  .upcoming-item {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: var(--dark-blue);
    border-color: var(--dark-blue);
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-chectopbar-components-wrapperked:not(
      .ant-radio-button-wrapper-disabled
    ) {
    color: #fff;
    background: var(--dark-blue);
    border-color: var(--dark-blue);
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: var(--dark-blue);
  }

  .ant-radio-button-wrapper:first-child {
    border-right-color: var(--dark-blue);
    border-radius: 7px 0 0 7px !important;
  }
  /* previous styles */

  #sixth-item {
    margin-left: 50px;
  }
  .result-timer {
    display: flex;
    align-items: center;
    padding: 10px 10px 0;
  }

  /* present styles */
  .presents-examGrid {
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 5px;
  }
  .present-ExamSubject {
    background: var(--white-font);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
  }
  .upcoming-item-timer {
    background: var(--white-font);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 15px 5px;
  }
  .ant-btn-primary {
    background-color: red;
  }

  .present-ExamBtn {
    background: var(--dark-blue);
    color: var(--white-font);
    flex: 0.5;
    padding: 25px 5px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0 5px 5px 0;
    justify-content: center;
  }
  .present-ExamBtn:hover {
    background: var(--dark-blue);
    color: var(--white-font);
  }
  .present-ExamGrid {
    display: flex;
    margin: 0 5px 10px;
  }
  .present-ExamBtnIcon {
    margin-right: 10px;
  }

  .present-img {
    width: 30px;
    margin: 0 10px 0 10px;
    height: 30px;
    border-radius: 100%;
    background: white;
    justify-content: center;
  }
  .present-img img {
    width: 20px;
  }
  .present-desc p {
    font-size: 11px;
  }
  .exam-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding: 0 0 0 0;
    border-bottom: 1px dashed var(--border);
  }

  .present-grid-item > p {
    padding: 5px;
    display: flex;
    justify-content: center;
    text-align: start;
  }
  .upcoming-div {
    margin-top: 10px;
    grid-template-columns: repeat(3, 1fr);
  }

  .upcoming-item-timer p {
    padding-left: 20px;
  }

  .sub-desc {
    padding-left: 0px !important;
  }
  /* global search*/
  .ant-input {
    background-color: var(--white-font);
    border: none;
    border-radius: 40px;
    height: 40px;
    position: relative;
    right: 0;
  }

  .ant-input-group-addon {
    background-color: var(--white-font);
    width: 150px;
    border-radius: 40px;
  }

  .ant-input-search-button {
    background-color: var(--white-font);
    border: none;
    outline: none;
    position: absolute;
    right: 20px;
    top: 0;
  }

  .anticon-search {
    text-align: center;
    // background-color: var(--white-font);

    // color: var(--dark-blue);

    position: absolute;
    right: 0;
  }

  
  .questionpaper_btn {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
  .questionpaper_btn:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
  .pdf-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .question-paper-item {
    margin-right: 10px;
    position: relative;
  }

  .files {
    font-size: 20px;
    color: #000;
  }

  .cross-icon {
    background-color: var(--blood-red);
    color: var(--white-font);
    padding: 3px;
    border-radius: 5px;
    position: absolute;
    top: -5px;
    right: -7px;
    font-size: 7px;
  }


  .mainradio{
    .ant-radio-button-wrapper{
      background: var(--white-font);
      border: none!important;
      border-radius: 5px 5px 0 0;
      margin: 0 3px 0 0;
      &::before{
        border: none!important;
        width: 0;
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
      background: var(--blue-new);
      border: none!important;
      border-radius: 5px 5px 0 0!important;
    }
  }


  @media (max-width: 500px) {
    .exam-btns {
      flex-direction: column;
      margin: 10px 10px 0;
    }

    .exams-btn {
      margin: 0px 0px 10px;
      width: 100%;
    }
    .add-examBtn {
      margin-left: 0rem;
    }

    .upcoming {
      flex-direction: column;
      margin: 50px 20px 0 20px;
    }
    .presents-examGrid {
      grid-template-columns: repeat(1, 1fr);
    }
    .present-ExamGrid {
      flex-direction: column;
    }
    .present-ExamBtn {
      padding: 10px 0px;
      margin-top: 2px;
      border-radius: 5px;
      text-align: center;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .radio-btns {
      margin-bottom: 20px;
    }
    .upcoming-item,
    .upcoming-item-timer {
      flex-direction: row;
    }

    .upcoming-item-timer p {
      font-size: 15px;
    }
    .upcoming-grid {
      grid-template-columns: repeat(1, 3fr);
      padding-top: 20px auto;
    }

    .upcoming-btns Button {
      background-color: red;
    }
    #sixth-item {
      margin-left: 0px;
    }
    .result-content {
      justify-content: center;
    }
  }
}
