.timetable {
  .clock-icon {
    margin-right: 10px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .headtitile {
      display: flex;
      gap: 10px;
      align-items: center;
      h3 {
        font-size: 18px;
      }
    }
  }
  .dateshow{
    background: var(--dark-blue);
    color: #fff;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;

  }
  .class-section-header {
    display: flex;
    gap: 10px;
  }
  .loading-spinner {
    margin-left: 50%;
    margin-top: 5%;
  }
  .no-timetable {
    padding: 15px;
    margin-left: 100px;
    margin-top: 20px;
    color: var(--dark-blue);
  }
  .timetable-griditem {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
  }
  .timetable-griditem small {
    padding: 10px 0 20px;
    justify-content: center;
  }

  .timetable-griditem span {
    margin-bottom: 5px;
  }
  .timetable-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    padding: 0;
  }
  .table-class {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 10px 0;
    border-radius: 5px 5px 0 0;
  }
  .table-class h4 {
    font-size: 18px;
    text-transform: capitalize;
  }
  .dashboard-grid {
    grid-template-columns: repeat(6, 1fr);
  }
  .text-style {
    color: var(--dark-blue);
    text-transform: capitalize;
  }
  .table-attendance {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 3px 0;
    border-radius: 0 0 5px 5px;
    .custom-switch {
      margin-right: 7px;
      background-color: "#808080";
    }
  }
  .ant-empty {
    margin-left: 20px;
  }
  .switch-checked,
  .switch-checked:hover {
    background-color: green;
  }

  .switch-unchecked,
  .switch-unchecked:hover {
    background-color: red;
  }
}

/* small screens */
@media (max-width: 768px) {
  .timetable-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .timetable-grid,
  .prev-exams-grid,
  .present-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

//new style

.timetableblock {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: var(--hover-blue);
  
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 130px;
  }

  .flextable {
    display: flex;
    align-items: baseline;
    // justify-content: center;
    width: 100%;
    padding: 14px;
    gap: 15px;
  }
  .flextable:nth-child(1) {
    background: var(--fc-border-color);
  }
  .flextable:nth-child(2) {
    background: var(--grey-bg);
  }
  .flextable:nth-child(3) {
    background: var(--fc-border-color);
  }
  .flextable:nth-child(4) {
    background: var(--grey-bg);
  }

  .ant-select {
    width: 100%;
    margin: 0 0 10px 0;
  }
span{
  display: inline-block;
}
  .t_itemname {
    width: 35%;
    display: flex;
    justify-content: end;
    font-weight: 600;
  }
  .t_subject {
    width:27%;
    align-items: center;
    display: flex;
    text-transform: capitalize;
    font-size: 14px;
  }

  .tableleft {
    width: 47.5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .tablecenter {
    width: 90px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: var(--dark-blue);
    text-transform: uppercase;
    color: var(--white-font);
    padding: 3% 0;
  }

  .tablright {
    width: 47.5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
::-webkit-scrollbar {
  height: 5px;
}
.timetable2nd {
  border: 1px solid var(--border);
  padding: 10px;
  margin: 10px 0;
  overflow: auto;
  border-radius: 10px;

  table {
    background: #fff;
    width: 100%;

    th {
      font-style: normal;
      font-weight: 600;
      padding: 10px 15px;
      font-size: 14px;
      background: #fafafa;
      text-align: left;
    }
    td:nth-child(1) {
      text-align: left;
    }
    td {
      padding: 3px;
      text-align: center;
      .letter_text {
        padding: 15px;
      }
      .lunchcontent {
        display: flex;
        flex-direction: column;
      }

      .ant-select {
        margin: 0 0 0 0;
        height: 46px;
        min-width: 110px;
        max-width: fit-content;
      }
    }
  }
}
