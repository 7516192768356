.rent-cards-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
  .ant-empty {
    margin-top: 60px;
    margin-left: 500px;
  }
}
.missing-btn {
  background-color: var(--blood-red);
  color: var(--white-font);
}
.missing-btn:hover {
  background-color: var(--blood-red);
  color: var(--white-font) !important;
}

.full-details-button {
  // background-color: var(--dark-blue);
  // color: var(--white-font);
  padding: 5px;
  border-radius: 5px;
}

.full-details-button:hover {
  // background-color: var(--dark-blue);
  // color: var(--white-font);
  padding: 5px;
  border-radius: 5px;
}
.missingbook-container {
  .header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 20px;
      color: black;
      font-weight: 500;
    }
  }
  .add-button {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
  .ant-empty {
    margin-top: 60px;
  }
}

.rent-card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  text-align: center;
  border-radius: 10px;
  width: 400px;
  padding:10px;
  margin: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}

.rent-card .right-side {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin: 0;
  padding: 13px;
  gap: 6px;
  font-size: 14px;

  p{
    text-transform: capitalize;
  }

}

.rent-card img {
  width: 150px;
  height: 150px;
}

.SuggestButton {
  background-color: #172b4d;
  color: #fff;
  margin: 0 10px 10px 0;
}
.SuggestButton:hover {
  background-color: #172b4d;
  color: #fff;
}
.SuggestButtonContainer {
  display: flex;
  justify-content: end;
  align-items: end;
}
