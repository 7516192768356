.notificationModal {
  /* //static code style */
  .card-list {
    // max-height: 550px;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin: 13px;
    margin-top: 0;
  
  }

  .date {
    font-weight: bold;
    display: block;
    font-size: 12px;
    font-weight: normal;
    color: var(--dsh-green);
  }

  .content {
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
  }

  .card {
    display: block;
    top: 0px;
    position: relative;
    background-color: white;
    border-radius: 4px;
    text-align: start;
    
    margin: 0;
    text-decoration: none;
    padding: 10px 0;
    z-index: 0;
    overflow: hidden;
    box-shadow: none;
    border-bottom: 1px dashed var(--border);
    // border: 1px solid #f2f8f9;
    // box-shadow: rgba(196, 194, 194, 0.3) 0px 1px 2px 0px,
    //   rgba(193, 193, 193, 0.3) 0px 2px 6px 2px;
    
  }

  .card .content {
  }

  .card:hover {
    transition: all 0.2s ease-out;
    // box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    // top: -1px;
    // border: 1px solid #cccccc;
    // background-color: white;
  }

  .card:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    /* background: #00838d; */
    background-color: #172b4d;
    height: 30px;
    width: 30px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
    display: none;
  }

  .card:hover:before {
    transform: scale(2.15);
  }

  /* Thin scrollbar */
  .card-list::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .card-list::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Thumb */
  .card-list::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  /* Thumb on hover */
  .card-list::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .card-list {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  .card-list::-moz-scrollbar-track {
    background-color: #f1f1f1;
  }

  .card-list::-moz-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  .card-list::-moz-scrollbar-thumb:hover {
    background-color: #555;
  }

  .read {
    background-color: white;

    /* Background color for read notifications (gray) */
  }

  .unread {
   
  }
}
