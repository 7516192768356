.transport-container {
  padding: 5px;
  .transport-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;

    h2{
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .trasportblock-main{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    .trasportblock{
      display: flex;
      align-items: center;
    }

  }
  .route-container {
    background-color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    h1{
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .ant-card-body {
    padding: 0px 20px;
    border-radius: 0 0 8px 8px;
    text-transform: capitalize;
  }
  .transport-card {
    background-color: #172b4d;
    color: #fff;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 300px;
    .name {
      font-size: 14px;
      font-weight: 800;
    }
    .time {
      font-size: 13px;
      font-weight: 700;
    }
    span {
      display: block;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .horizontal-line {
    margin-left: 10px;
    height: 2px;
    width: 50px;
    background-color: #000;
    margin-top: 10px;
  }
  .save-button {
    float: right;
    margin-top: 20px;
  }
  .school-imgage {
    width: 70px;
    height: 70px;
  }
}
