.staff-attendance-container {
  .header-container {
    margin: 0 0 0 0;
  }

  .status-container {
    display: flex;

    .check-cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 15px;
      align-items: center;
     
    }
    
    .switch-checked,
    .switch-checked:hover {
      background-color: rgb(129, 248, 129);
    }

    .switch-unchecked,
    .switch-unchecked:hover {
      background-color: #c95555;
    }
  }

  .card-block {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .check-cards {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      padding: 18px 10px;
      background: #fff;
      width: 50%;
      border-radius: var(--radius10);
      font-size: 14px;
      align-items: center;
      .checkinout{
        display: flex;
        gap: 5px;
      }

      p {
        font-size: 15px;
        font-weight: 600;
      }
    }
    .check-cards:nth-child(1) {
      border-left: 5px solid var(--dsh-brown);
    }

    .check-cards:nth-child(2) {
      border-left: 5px solid var(--dsh-green);
    }
  }
  .loading-spinner {
    margin-left: 50%;
    margin-top: 10%;
  }

  .my-attenace-container {
    display: flex;
    gap: 15px;
    flex-wrap: nowrap;
    padding: 0;
  }
  .event-divide {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .cardsection {
    width: 70%;
    .Events-container {
      background: var(--white-font);
      padding: var(--padding10);
      border-radius: var(--radius10);
      .header-text {
        margin-bottom: 20px;
      }
      .eventarticle {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }
      .single-data {
        padding: 20px 10px;
        margin-bottom: 5px;
        border-radius: 5px;
        width: 23.5%;
        display: flex;
        text-align: center;
        flex-direction: column;

        .event-date {
          width: 70px;
          height: 70px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          margin: 0 auto;
          margin-bottom: 10px;
          flex-direction: column;
          font-weight: 600;
          font-size: 24px;
          span {
            font-size: 14px;
            font-weight: 400;
          }
        }
        .event-month {
          margin-bottom: 5px;
          color: var(--text-light);
          font-size: 14px;
        }
        .event-title {
          font-weight: 600;
        }
      }
      .single-data:nth-child(1) {
        background: var(--dsh-purple-light);
        .event-date {
          background: var(--dsh-purple);
          color: var(--white-font);
        }
      }
      .single-data:nth-child(2) {
        background: var(--dsh-blue-light);
        .event-date {
          background: var(--dsh-blue);
          color: var(--white-font);
        }
      }
      .single-data:nth-child(3) {
        background: var(--dsh-brown-light);
        .event-date {
          background: var(--dsh-brown);
          color: var(--white-font);
        }
      }
      .single-data:nth-child(4) {
        background: var(--dsh-pink-light);
        .event-date {
          background: var(--dsh-pink);
          color: var(--white-font);
        }
      }
      .single-data:nth-child(5) {
        background: var(--dsh-purple-light);
        .event-date {
          background: var(--dsh-purple);
          color: var(--white-font);
        }
      }
      .single-data:nth-child(6) {
        background: var(--dsh-blue-light);
        .event-date {
          background: var(--dsh-blue);
          color: var(--white-font);
        }
      }
      .single-data:nth-child(7) {
        background: var(--dsh-brown-light);
        .event-date {
          background: var(--dsh-brown);
          color: var(--white-font);
        }
      }
      .single-data:nth-child(8) {
        background: var(--dsh-pink-light);
        .event-date {
          background: var(--dsh-pink);
          color: var(--white-font);
        }
      }
    }
  }
  .calenderblock{
    padding: 10px 0;
    font-size: 14px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    .eventlist{
      background: var(--white-font);
      padding: var(--padding10);
      border-radius: var(--radius5);
      display:flex;
      justify-content: space-between;
      align-items: center;   
      .listtop{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .circledaa{
        width: 15px;
        height: 15px;
        background: var(--dsh-blue);
        border-radius: 100px;
      }  

      .subday{
        color: var(--text-light);
        font-size: 12px;
      }
      
    }
  }

}
