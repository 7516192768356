.mettings-container {
 
  .table-div {
    margin-top: 5px;
  }
  .meetings-header {
    margin: 0 0 10px 0px;
    h2 {
      font-size: 18px;
      font-weight: 600;
      color: black;
    }
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .right-icon-btn {
    background-color: green !important;
    color: #fff;
  }
  .view-icon-btn {
    background-color:var(--blood-red) !important;
    color: #fff;
  }
  .ant-btn {
    background-color: var(--dark-blue);
  }
  .Add_button {
    background-color: var(--dark-blue);
    color: var(--white-font);
    height: 35px;
    border-radius: 8px;
  }

  .Add_button:focus {
    background-color: var(--dark-blue);
    color: var(--white-font);
    height: 40px;
  }
  .Add_button:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
    height: 40px;
  }
  .ant-btn:hover {
    background-color: var(--dark-blue);
  }
  .anticon-delete {
    color: var(--blood-red);
  }
  .ant-table-tbody .ant-table-cell .anticon{
    padding: 4px;
  }

  .scedule_button {
    color: #fff;
    border-color: var(--dark-blue);
    background: var(--dark-blue);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 5px;

    &:hover {
      background: var(--dark-blue);
      color: #fff;
    }
  }
}
@media (max-width: 800px) {
  .mettings-container {
    margin: 140px 0px 0px 20px;
  }
}
@media (max-width: 600px) {
  .mettings-container {
    margin: 140px 0px 0px 20px;
  }
}
