.resignation-Edit-modal {
 
    .buttons {
    color: #fff;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 0px 18px 0px 18px;
  }

}

.approve-modal {
  .modal-buttons-row {
    margin-bottom: 20px;
  }

  .approval-content {
    text-align: center;
    h1 {
      font-size: 17px;
      font-weight: 500;
    }
  }


}
