.syllabus-list {
  .ant-progress .ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 100px;
    height: 5px;
  }
  .dropdown-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .divdrop {
      display: flex;
      gap: 10px;
    }

    .header-text {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .cards-section {
    display: flex;
  }
  .loading-spinner {
    margin-left: 50%;
    margin-top: 30%;
  }
  .chapter-container {
    display: flex;
    justify-content: space-between;
  }
  .progress-bar {
    padding-right: 10px;
  }
  .ant-progress-line {
    width: 120px;
  }
  .cards {
    width: 700px;
    height: 450px !important;
    overflow-y: scroll;
    h3,
    .topic-status {
      text-transform: capitalize;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .ant-empty {
    margin-top: 50px;
  }
  .ant-card-head {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 10px 10px;
    min-height: auto;
    font-size: 14px;
  }
  .ant-card-body {
    padding: 10px 0px;
  }
  .staus-display {
    display: flex;
    justify-content: space-between;
  }

  .header-status {
    display: flex;
    gap: 60px;
    span {
      color: white;
    }
  }
  .topic-status {
    position: relative;
    right: 0;
    display: flex;
    text-align: center;
    h4 {
      width: 170px;
      text-align: left;
      white-space: normal;
    }
    p {
      position: relative;
      font-size: 11px;
      font-weight: 600;
      text-align: center;
      color: var(--lemon-green);
    }
    .action-icon {
      position: absolute;
      right: 10px;
      display: flex;
      gap: 5px;
    }
  }

  .status-display {
    display: flex;
    justify-content: space-between;
  }
  .status-display.active {
    background-color: #7693c5;
    color: var(--white-font);
    border-radius: 3px;
  }
  .right-icon-btn {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 2px;
    border-radius: 5px;
  }
  .right-icon {
    background-color: green;
    color: var(--white-font);
    padding: 2px;
    border-radius: 5px;
  }
}
