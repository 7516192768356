.students-container {

  .header-container {
    display: flex;    
    justify-content: space-between;
    margin-bottom:10px;
    align-items: center;
    padding: 0;
  }
  .image-container {
    display: flex;
    align-items: center;
  }
.dropdown-div{
  display: flex;
  gap: 10px;
}
  .avatar-img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .student-name {
    margin-right: 8px;
    flex: 1;
  }
}
