.books-container {
  display: flex;
 gap: 15px;
  flex-wrap: wrap;
}

.header-container {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  h3{
    font-size: 18px;
    font-weight: 600;
  }
  h4 {
    color: var(--dark-blue);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  .loading-spinner {
    margin-left: 50%;
  }
}
.card-container {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 10px;
  width: 170;

  img.card-image {
    width: 100%;
    height: 150px;
  }
  .icon-container{
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    color: var(--orange);
  }
  .author-card-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .auspace{
      margin: 10px 0;
      min-height: 65px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-direction: column;

      .publishname{
        color: darkcyan;
      }
     
    }
   
  
  }
}
