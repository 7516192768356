.staff-result {

  .lemon {
    color: var(--lemon-green);
  }
  .search-button {
    border-radius: 5px;
    background-color: var(--dark-blue);
    color: var(--white-font);
    height: 35px;
    text-align: center;
  }
  .search-button:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
  // .table {
  //   padding-top: 5px;
  // }

  .anticon-info-circle {
    background: var(--dark-blue);
    color: #fff;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  
  .asheder{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    .header-text {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 500;
    }

    .headeright{
      display: flex;
      gap: 10px;
    }
  }
  .searchButton {
    display: flex;
    align-items: center;
  }

  .assignments {
    // justify-content: space-between;

    // display: flex;
    // align-items: center;
    // text-align: center;
  }
  .results-details {
    grid-template-columns: repeat(3, 1fr);
    border-radius: 10px;
    margin: 5px;
    background: #ffffff;
    padding: 15px 30px;
  }
  .result-detail-item {
    border: 1px solid #848ff0;
    border-radius: 10px;
    padding: 10px 20px;
  }
  .result-item-img {
    background: var(--grey-bg);
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 50%;
  }

  .assignment > h3 {
    margin-right: 20px;
  }
  .result-item-desc {
    margin-left: 20px;
  }
  .result-item-desc small {
    color: #848ff0;
  }
  .assignment-icon {
    margin-right: 5px;
  }
  .result-item-text {
    display: flex;
    flex-direction: column;
  }

  .assignment-inputs {
    display: flex;
    flex-direction: column;
  }
  .ant-select-single .ant-select-selector {
    border-radius: 5px;
  }

 
  .ant-select-single .ant-select-selector {
    text-align: center;
  }

  .assignment-inputs small {
    margin-left: 20px;
  }

  .assignment-inputs input::placeholder {
    font-size: 14px;
    text-align: center;
  }
  .search-btns {
    background: var(--lemon-green);
    padding: 10px 25px;
    border-radius: 20px;
    margin-top: 15px;
    color: #ffffff;
  }
  .student-dropdown {
    margin-left: 35.5px;
  }
  .class-dropdown {
    margin-left: 12px;
  }
  .section-dropdown {
    margin-left: 12px;
  }
  .unit-test-dropdown {
    margin-left: 12px;
  }
  .subject-dropdown {
    margin-left: 12px;
  }
  /* small screens */

  @media (max-width: 768px) {
    .assignments,
    .assignment {
      flex-direction: column;
    }
    .assignment-inputs,
    .assignment-inputs input {
      margin-top: 10px;
    }

    .search-btns {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 500px) {
    .results-details {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
