.checkout-modal {
  .checkout-form {
    .from-header {
      display: flex;
      background-color: #172b4d;
      color: #fff;
      padding: 10px;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    height: 40px;
    line-height: 1.5714285714285714;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }
  .defaul-btn:hover {
    background-color: #ffffff;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    color: #000;
  }
  //   .ant-btn-default {
  //   background-color: #ffffff;
  //   border-color: #d9d9d9;
  //   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  // }
}
