.checkout-container {
  background-color: #fff;
  .checkout-header {
    padding-bottom: 10px;
  }
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-left: 20px;
  }
  .address-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px;
    border: 0.5px solid rgb(112, 111, 111);
    cursor: pointer;
    border-radius: 5px;
  }
  .add-address {
    width: 100%;
    height: 80px;

    overflow: scroll;
    padding: 10px;
    border: 0.5px solid rgb(112, 111, 111);
    border-radius: 5px;
  }
  .add-address-text {
    span {
      color: #4a8bfa;
      margin-bottom: 5px;
    }
  }
  .annoucement-btns {
    display: flex;
    gap: 5px;
  }

  .button-div {
    margin-top: 5px;
  }
}
