.assignment-modal {
  padding: 10px;

  .ant-form-item {
    width: auto !important;
  }
  .file_uploading-icon {
    display: flex;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .anticon-file-pdf {
    color: var(--dark-blue);
    font-size: 25px;
  }
  .anticon-close-square {
    color: var(--blood-red);
    position: absolute;
    bottom: 45px;
    // padding-bottom: 47px;
    font-size: 10px;
    // font-weight: 400;
  }
  .ant-picker {
    background-color: var(--white-font);
    width: 100% !important;
    height: 42px;
    border-radius: 5px;
  }
  .ant-picker-input {
    background-color: var(--white-font);
    border-radius: 5px;
    text-align: end;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-select {
    margin: 0;
  }
  .ant-select-single .ant-select-selector {
    background-color: #fff;
    height: 40px;
    border-radius: 5px;
    text-align: left !important;
  }
  .ant-select-single .ant-select-selector:hover {
    border: 1px solid var(--grey-bg);
    outline: none;
  }
  .antd-row .ant-form-item-row {
    background-color: var(--grey-bg);
  }
  .ant-input {
    background-color: var(--white-font);
    border-radius: 5px;
    height: 42px;
  }
}
.view-assignment {

  .ant-table {
  
    padding: 0!important;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 5px;
 
  }
  :where(.css-dev-only-do-not-override-p7e5j5).ant-modal .ant-modal-body{
    padding-top: 0!important;
  }
  .ant-table table {
    background: none;
    border-spacing: 0 3px !important;
    border-collapse: separate !important;
    padding: 0!important;
  }
  .ant-table table {
    tr {
      td:nth-child(1){
        font-weight: 600!important;
      }
    }

  }
  
}
