.studentLeaveContainer {


  .full-details-button {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 0px 8px;
    border-radius: 5px;
  }

  .full-details-button:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 0px 8px;
    border-radius: 5px;
  }
}
