.resignation-View-modal {
  .flex-div {
    display: flex;
    gap: 20px;
    span {
      font-size: 18px;
    }
    p {
      font-size: 15px;
      font-weight: 600;
    }
  }
}
