.announcment-container {
  
.headmainann{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  
  
  .annheader{
    display: flex;
    align-items: center;
    gap: 10px;
    margin:0 0 10px 0;
  

    h2{
      font-size: 18px;
      font-weight: 600;
    }
 
  }
}
.datetime{
  display: flex;
  align-items: center;
  justify-content: space-between;

}
:where(.css-dev-only-do-not-override-p7e5j5).ant-modal .ant-modal-body{
  padding: 0!important;
}

.submit-button{
  margin-top: 10px;
}

.ant-select-selection-item{
  font-size: 14px;
}
.select-option{
  gap: 10px;
  display: flex;
  justify-content: space-between;
}
.ann-head{
  padding: 10px;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-card .ant-card-body {
    padding: 0;
    border-radius: 0 0 8px 8px;
}


  .annouce-item {
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .annouce-item small {
    color: var(--white-font);
    margin-left: 35px;
    padding: 3px 20px;
    background-color: var(--dark-blue);
    border-radius: 0px 0px 5px 5px;
    font-weight: 500;
  }
  .annouce-item h3 {
    font-size: 15px;
  }
  .annuncement-card {
    position: relative;
    top: 0;

    small {
      color: var(--white-font);
      padding: 7px 20px;
      background-color: var(--dsh-green);
      border-radius: 0 5px 5px 0;
      font-weight: 500;      
      width: 100px;
      margin: 10px 0 0 0;
      display: inline-block;
    }
  }
  .ant-select-single .ant-select-selector {
    text-align: center;
  }
}
