.staff-attenance {
  .headerblock-staff {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0;
    position: relative;
    left: 0;
    .header-text {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
    }
    .hright {
      display: flex;
      gap: 10px;
      position: absolute;
      top: -60px;
      right: 5px;
    }
  }

  .absent-btn {
    background: var(--blood-red) !important;
    color: var(--white-font) !important;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: var(--dsh-green);
    border-color: var(--dsh-green);
  }

  .assignments {
    display: flex;
    justify-content: space-between;
  }

  .attenance_button {
    color: #fff;
    border-color: var(--dark-blue);
    background: var(--dark-blue);

    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 4px;

    &:hover {
      background: var(--dark-blue);
      color: #fff;
    }
  }
  .attendance-status {
    text-align: left;
  }
  .attendance-header {
    display: flex;
    justify-content: space-between;
  }

  .full-details-button {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 5px;
    border-radius: 5px;
  }

  .full-details-button:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 5px;
    border-radius: 5px;
  }
  .subject-btn {
    background-color: var(--white-font);
    color: var(--dark-blue);
    width: "auto";
    padding: 8px;
    width: "auto";
    text-transform: capitalize;
    font-size: 15px;
    border-radius: 5px;
  }

  .save-btn {
    background-color: var(--dark-blue);
    color: var(--white-font);
    text-align: center;
    height: 38px;
    margin-bottom: 10px;
  }
  .save-btn:hover {
    color: var(--white-font);
  }
  .attenance-dropdowns {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .subject-dropdown {
    text-transform: capitalize;
  }
  .tables {
    padding-top: 10px;
  }
  .loading-spinner {
    margin-left: 50%;
  }
  .present-style input:checked + span {
    background-color: white;
  }

  .ant-radio-checked .ant-radio-inner:focus {
    border-color: red;
  }
  .absent-style input:checked + span {
    background-color: red;
  }
  .custom-modal {
    #upload-inputs {
      background-color: #fff;
      height: 40px;
      padding: 8px;
      width: 360px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      font-size: 13px;
    }
    .submit {
      background-color: var(----blood-red);
      text-align: center;
      width: 100px;
    }
  }

  .custom-modal span {
    font-size: 15px;
    font-weight: 400;
    padding-right: 5px;
  }
  .custom-modal .ant-modal-footer {
    background-color: #2aa84c;
  }
  .attendance_button {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
  .attendance_button[disabled] {
    background-color: #3b5076;
    color: var(--white-font);
  }
  .attendance-status span {
    background: #ffffff;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-right: 3px;
    font-size: 10px;
    padding: 5px 3px;
    font-weight: 400;
  }

  .check-icon {
    color: var(--lemon-green);
  }
  .close-icon {
    color: #ff0000;
  }
  .icon-sty {
    font-size: 18px;
    padding-top: 8px;
  }
  .clocks-icon {
    color: var(--lightblue);
  }

  #header-row {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  #header-row #header-row-item {
    width: 100%;
    flex: 1;
    justify-content: space-between;
  }
  #header-row-item th {
    padding: 0 10px;
  }

  #days-row-item th {
    padding: 0 5px;
  }
  #attendance-body-item tr td {
    padding: 4px 0;
    margin: 0 20px;
  }
  #view-btn {
    padding: 6px 20px;
  }

  .attendances-grid {
    grid-template-columns: repeat(6, 1fr);
    margin: 10px 120px;
  }

  .attend-grid-item h1 {
    padding: 20px 0;
  }

  .calander-styling {
    background-color: #e5e5e5;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    font-weight: bold;
  }
}
@media (max-width: 600px) {
  .staff-attenance {
    .attenance-dropdowns {
      flex-direction: column;
      gap: 15px;
      padding-bottom: 10px;
    }
  }
}
@media (max-width: 600px) {
}
@media (max-width: 768px) {
}
