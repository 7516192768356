.myProfile {
  padding-right: 10px;
  margin-top: 10px;
  .ant-form-item {
    width: auto !important;
  }
  .ant-picker {
    background-color: var(--white-font);
    width: 100% !important;
    height: 42px;
    border-radius: 5px;
  }
  .ant-picker-input {
    background-color: var(--white-font);
    border-radius: 5px;
    text-align: end;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-select {
    margin: 0;
  }
  .ant-select-single .ant-select-selector {
    background-color: #fff;
    height: 40px;
    border-radius: 5px;
  }
  .ant-select-single .ant-select-selector:hover {
    border: 1px solid var(--grey-bg);
    outline: none;
  }
  .antd-row .ant-form-item-row {
    background-color: var(--grey-bg);
  }
  .ant-input {
    background-color: var(--white-font);
    border-radius: 5px;
    height: 42px;
  }

  .ant-select-single .ant-select-selector {
    text-align: left !important;
  }
  .profile-card {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: auto;
    width: 100%;
    margin-bottom: 15px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    gap: 10px;
  }
  .ProfileName {
    width: 300px;
    text-align: start;
    text-transform: capitalize;
    text-align: center;
    background: var(--dsh-blue-light);
    padding: 22px;

    .profiletext{
      font-size: 20px;
      font-weight: 600;
    }
    .name {
      font-size: 16px;
      font-weight:600;     
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      background-color: var(--dark-blue);
      color: var(--white-font);
      padding: 5px 15px;
      border-radius: 5px;
    }
  }
  .subjects-card {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 40%;
    height: auto;
    margin-bottom: 15px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    h1 {
      margin-bottom: 15px;
    }
  }
}
