.my-table {
  margin: 10px;
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 2px; */
}

.my-table th,
.my-table td {
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.custom-calendar .ant-radio-group {
  display: none;
}
.flex-container {
  display: flex;
  gap: 10px;
}
.fixed-width {
  flex: 0 0 400px;
}
.Events-container {
  background: var(--white-font);
  padding: var(--padding10);
  border-radius: var(--radius10);
  width: 100%;
  flex: 1;
  overflow: scroll;
  .header-text {
    margin-bottom: 20px;
    text-align: start;
  }
  .eventarticle {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .single-data {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    width: 30.5%;
    display: flex;
    text-align: center;
    flex-direction: column;

    .event-date {
      width: 70px;
      height: 70px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      margin: 0 auto;
      margin-bottom: 10px;
      flex-direction: column;
      font-weight: 600;
      font-size: 24px;
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .event-month {
      margin-bottom: 5px;
      color: var(--text-light);
      font-size: 14px;
    }
    .event-title {
      font-weight: 600;
    }
  }
  .single-data:nth-child(1) {
    background: var(--dsh-purple-light);
    .event-date {
      background: var(--dsh-purple);
      color: var(--white-font);
      font-size: 16px;
    }
  }
  .single-data:nth-child(2) {
    background: var(--dsh-blue-light);
    .event-date {
      background: var(--dsh-blue);
      color: var(--white-font);
      font-size: 16px;
    }
  }
  .single-data:nth-child(3) {
    background: var(--dsh-brown-light);
    .event-date {
      background: var(--dsh-brown);
      color: var(--white-font);
      font-size: 16px;
    }
  }
  .single-data:nth-child(4) {
    background: var(--dsh-pink-light);
    .event-date {
      background: var(--dsh-pink);
      color: var(--white-font);
      font-size: 16px;
    }
  }
  .single-data:nth-child(5) {
    background: var(--dsh-purple-light);
    .event-date {
      background: var(--dsh-purple);
      color: var(--white-font);
      font-size: 16px;
    }
  }
  .single-data:nth-child(6) {
    background: var(--dsh-blue-light);
    .event-date {
      background: var(--dsh-blue);
      color: var(--white-font);
      font-size: 16px;
    }
  }
  .single-data:nth-child(7) {
    background: var(--dsh-brown-light);
    .event-date {
      background: var(--dsh-brown);
      color: var(--white-font);
      font-size: 16px;
    }
  }
  .single-data:nth-child(8) {
    background: var(--dsh-pink-light);
    .event-date {
      background: var(--dsh-pink);
      color: var(--white-font);
      font-size: 16px;
    }
  }
}
.custom-calendar .ant-picker-calendar-year-select {
  display: none !important;
}

.my-table th {
  background-color: #f90202;
  font-weight: bold;
}

.CreateButton {
  background-color: #000066;
  color: white;
  border-radius: 2px;
}

.loadingHolidaysCss {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingHolidays {
  height: 770px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingCallenderCss {
  height: 790px;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolTip {
  background-color: #050505;
  color: #ffffff;
  padding: 10px;
}

.rightbtn {
  text-align: right;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-form-item {
  margin-bottom: 10px;
}

.submit {
  background-color: var(--blood-red);
  text-align: center;
  width: 100px;
  color: #fff;
  border-radius: 5px;
  border: none;
}

//holiday style
.tabholidayview {
  display: flex;
  text-align: center;
  gap: 10px;
  .calender_div {
    border-radius: 5px;
  }
  .holidadyleft h2 {
    font-size: 18px;
    font-weight: 600px;
    margin: 15px 0;
  }
  .sidecard-div {
    background-color: var(--white-font);
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 10px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    .upcoming-holiday {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 15px;
      background-color: var(--dark-blue);
      padding: 5px;
      color: #fff;
      border-radius: 5px;
    }
  }
  .ant-picker-calendar {
    border-radius: 5px !important;
  }
  .calender-texts {
    text-align: center;
    text-transform: capitalize;
    background-color: rgb(255, 167, 3);
    border-radius: 2px;
    padding: 15px 0;
    border-radius: 5px;
    span {
      display: block;
      font-size: 13px;
      font-weight: bold;
    }
    .type {
      font-size: 13px;
      background: #f7d366;
      width: auto;
      padding: 2px 10px;
      margin: 8px 5px 0 5px;
      border-radius: 4px;
      display: inline-block;
    }
  }
  .ant-picker-calendar .ant-picker-calendar-header {
    font-size: 13px;
    // background: #f7d366;
    width: auto;
    padding: 2px 10px;
    margin: 8px 5px 0 5px;
    border-radius: 4px;
    display: inline-block;
  }
}

.holidadyleft {
  // height: 767px;
  text-align: start;
  overflow: auto;
}

@media (max-width: 650px) {
  .rightbtn {
    text-align: left;
    margin: 10px 0;
  }

  .tabholidayview {
    display: flex;
    padding: 4px;
    flex-direction: column;
  }

  .holidadyleft {
    height: auto;
    overflow: auto;
  }

  .holidayCard {
    width: auto;
  }

  .ant-radio-group {
    width: 100%;
  }

  .RadioButtons {
    width: 50%;
  }

  .ant-select-single .ant-select-selector {
    text-align: center;
  }
}
