.holiday-wrapper-block {

.holidayradio{
  margin-bottom: 10px;
  border-bottom: 1px dashed var(--border);
}


  .holidayheader {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h1 {
      font-size: 20px;
      margin: 0;
    }
  }
  .holidays {
    display: flex;
    margin-top: 20px;
  }
  .ant-picker {
    width: auto !important;
    background: none;
    border: none;
    padding: 0;
  }

  .ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    padding: 0;
    height: 40px;
    margin-bottom: 15px;
  }

  .ant-btn-link {
    border-color: transparent;
    background: var(--dark-blue);
    box-shadow: none;
    color: #fff;
    margin: 0 0 7px 0;
    border-radius: 5px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-tab {
    background: #fff;
  }
  .ant-tabs-content-holder {
    flex: auto;
    min-width: 0;
    min-height: 0;
    background: #fff;
    padding: 15px;
    border-radius: 0 8px 8px 8px;
    .ant-tabs-content-holder {
      flex: auto;
      min-width: 0;
      min-height: 0;
      background: #fff;
      padding: 0;
      border-radius: 0 8px 8px 8px;
    }
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 10px 10px 0 0;
    &:hover {
      background: var(--dark-blue);
      color: var(--white-font);
    }
  }
  .ant-tabs-tab-active {
    margin: 0;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 10px 10px 0 0;
    background: var(--dark-blue);
    color: var(--white-font);

    .ant-tabs-tab-btn {
      color: var(--white-font);
    }
  }

  .ant-picker-input {
    background-color: #fff;
    text-align: end;
    height: 37px;
    padding: 0 9px;
    border-radius: 5px;
  }
  .holiday {
    flex: 0.7;
  }
  .leaves {
    flex: 0.3;
  }

  .holidays-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 40px !important;
    display: flex;
  }
  #darkblue-bg {
    background: #172b4d;
  }

  .flex-sty {
    display: flex;
    align-items: center;
  }

  .student-article {
    display: flex;

    .flexDir-sty {
      display: flex;
      align-items: self-end;
    }

    select {
      background: #fff;
      border-radius: 5px;
      padding: 5px 15px;
      min-width: 100px;
    }
    input {
      background: #fff;
      border-radius: 5px;
      padding: 5px 15px;
      min-width: 100px;
      font-size: 13px;
    }
  }

  .db-staff-title {
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #ccc;

    .staff-view {
      background-color: var(--pitch-green);
      padding: 5px 20px;
      font-weight: 400;
      border-radius: 20px;
      color: var(--white-font);
      font-size: 14px;
    }
  }

  .db-staff-item {
    justify-content: space-between;
    align-items: center;
    margin: 7px 0;
    padding: 8px 10px;
  }
  .db-staff {
    background: #fff;
    padding: 0;
    border-radius: 5px;
  }
  .staff-viewBtn {
    background-color: var(--lemon-green);
    padding: 5px 20px;
    font-weight: 400;
    border-radius: 20px;
    color: var(--white-font);
  }

  .teacherSearch-btns {
    background: #4aa105;
    margin-left: 10px;
    color: #fff;
    border-radius: 5px;
    margin: 0 15px;
    padding: 5px 30px;
    font-size: 13px;
  }
  .holiday-item {
    background: #4aa105;
    border-radius: 10px;
    text-align: center;
    padding: 0 10px;
    color: var(--white-font);
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .holiday-item h1 {
    margin: 0 10px;
    font-size: 30px;
    color: var(--white-font);
  }
  .d-flex {
    display: flex;
  }
  .tables {
    margin-top: 0;
  }

  .holiday-item span {
    font-size: 13px;
    padding-bottom: 0;
  }

  /* tables */
  .holiday-table {
    width: 90%;
    margin-right: 30px;
    min-width: 700px;

    tbody tr .holiday-data {
      // background-color: transparent !important;
      padding: 0px !important;
      margin: 0 !important;
    }
    thead {
      th {
        background: none;
        padding: 10px 0 0 0;
        text-align: center;
      }
    }
    tbody tr td {
      background: none;
    }
    tbody tr td > .attendance-box {
      padding: 0;
      margin: 0 5px;
      background: #fff;
      min-height: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }
  }

  .attend-check {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .attend-check small {
    font-size: 9px;
    color: #096cc8;
  }
  .span-text {
    font-size: 10px;
    color: white;
    border-radius: 10px;
    padding: 3px 7px;
  }
  .attend-icon {
    margin-right: 5px;
  }
  .disabled {
    opacity: 0.6;
  }

  .db-staff-profile img {
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }

  .rankers-text {
    line-height: 18px;
    font-size: 14px;
    h3 {
      margin: 0;
      font-size: 14px;
    }
  }

  .rankers-text small {
    color: #096cc8;
    font-size: 14px;
  }


  .mainradio{
    .ant-radio-button-wrapper{
      background: var(--white-font);
      border: none!important;
      border-radius: 5px 5px 0 0;
      margin: 0 3px 0 0;
      &::before{
        border: none!important;
        width: 0;
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
      background: var(--blue-new);
      border: none!important;
      border-radius: 5px 5px 0 0!important;
    }
  }









  @media (max-width: 768px) {
    .holidays {
      flex-direction: column;
    }
  }
  @media (max-width: 500px) {
    .holidays-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px !important;
    }
  }
}
