.leaves-wrapper {
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: var(--dark-blue);
    border-color: var(--dark-blue);
  }
  .leavetopsection {
    display: flex;   
    align-items: center;

  }
  .inputBox {
    border: 0.5px dashed black;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-chectopbar-components-wrapperked:not(
      .ant-radio-button-wrapper-disabled
    ) {
    color: #fff;
    background: var(--dark-blue);
    border-color: var(--dark-blue);

    &:hover {
      background: var(--dark-blue) !important;
      color: var(--dark-blue);
    }

    &:focus {
      background: var(--dark-blue) !important;
      border: none;
    }
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: var(--dark-blue);
  }

  .ant-radio-button-wrapper:first-child {
    border-right-color: var(--dark-blue);
    border-radius: 7px 0 0 7px !important;
  }

  .ant-radio-button-wrapper:last-child {
    border-right-color: var(--dark-blue);
    border-radius: 0px 7px 7px 0px !important;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0 7px 7px 0;
  }

  .ant-radio-button-wrapper-checked {
    background: var(--dark-blue) !important;
  }
}

//leave request
.addleave-modal {
  .inputBox {
    border: 0.5px solid black;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    width: 160px;
  }
}
