.empty-notification {
  display: flex;
  flex-direction: column;
  text-align: center !important;
  align-items: center;
  .icon {
    font-size: 100px;
    font-weight: 200;
    color: rgb(141, 140, 140);
  }

  .notification-message {
    margin-top: 10px;
    color: rgb(142, 140, 140);
    font-size: 15px;
  }
}
