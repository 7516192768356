.loading-spinner {
  margin-left: 50%;
  margin-top: 5%;
}
.add-button-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .button-style {
    background-color: var(--dark-blue);
    color: white;
  }
  .regularization-table {
    margin-bottom: 3px;
  }
}
.regrow {
  gap: 10px;
  .closebtn {
    background: var(--blood-red);
    color: var(--white-font);
    &:hover {
      color: var(--white-font) !important;
    }
  }
}
.unlock-header {
  padding: 10px 0;
  position: relative;
  top: 0;
  .request-btn {
    position: absolute;
    top: -45px;
    right: 5px;
  }
}
