
.library-wrapper{
  
  .topradio{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
    margin: 0 0 20px 0;
    padding: 0;
  }

 .books-count{
    background: var(--dsh-green);
    color: var(--white-font);
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }
  
  .mainradio{
    .ant-radio-button-wrapper{
      background: var(--white-font);
      border: none!important;
      border-radius: 5px 5px 0 0;
      margin: 0 3px 0 0;
      &::before{
        border: none!important;
        width: 0;
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
      background: var(--blue-new);
      border: none!important;
      border-radius: 5px 5px 0 0!important;
    }
  }
}