
.studentattendance-wrapper{

.radioparent{
    border-bottom: 1px dashed var(--border);
    .mainradio{
      .ant-radio-button-wrapper{
        background: var(--white-font);
        border: none!important;
        border-radius: 5px 5px 0 0;
        margin: 0 3px 0 0;
        &::before{
          border: none!important;
          width: 0;
        }
      }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          background: var(--blue-new);
          border: none!important;
          border-radius: 5px 5px 0 0!important;
        }
      }
}

}