.studymaterial-modal {
  .ant-form-item {
    width: auto !important;
  }
  .ant-picker {
    background-color: var(--white-font);
    width: 100% !important;
    height: 42px;
    border-radius: 5px;
  }
  .ant-picker-input {
    background-color: var(--white-font);
    border-radius: 5px;
    text-align: end;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-select {
    margin: 0;
  }
  .file_uploading-icon {
    display: flex;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .anticon-file-pdf {
    color: var(--dark-blue);
    font-size: 25px;
  }
  .anticon-close-square {
    color: var(--blood-red);
    position: absolute;
    bottom: 45px;
    // padding-bottom: 47px;
    font-size: 10px;
    // font-weight: 400;
  }

  .ant-select-single .ant-select-selector {
    background-color: #fff;
    height: 40px;
    border-radius: 5px;
    text-align: left !important;
  }
  .ant-select-single .ant-select-selector:hover {
    border: 1px solid var(--grey-bg);
    outline: none;
  }
  .antd-row .ant-form-item-row {
    background-color: var(--grey-bg);
  }
  .ant-input {
    background-color: var(--white-font);
    border-radius: 5px;
    height: 42px;
  }
}
