.staff-study-material {
  

  .staff-header{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    padding: 0 0 10px 0;
    justify-content: space-between;

    .header-text{
      font-size: 18px;
      font-weight: 600;
    }
      .rightheader{
        display: flex;
        gap: 10px;
      }
  }


  .assignments {
    display: flex;
    justify-content: space-between;
  }
  .studymaterial-header {
    display: flex;
    justify-content: space-between;
  }
  .ant-select-single .ant-select-selector {
    text-align: center;
  }

  .my-span {
    font-weight: 600;
    color: #333;
    font-size: 18px;
    padding-right: 15px;
  }
  .upload-study-button {
    border-radius: 5px;
    background-color: var(--dark-blue);
    color: var(--white-font);
    // height: 35px;
    cursor: pointer;
  }
  .upload-study-button :hover {
    border-radius: 5px;
    background-color: var(--dark-blue);
    color: var(--white-font);
    // height: 35px;
    cursor: pointer;
  }
  .custom-span {
    color: red;
    font-size: 16px;
  }
  .data-unavailable {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .ant-select-single .ant-select-selector {
    border-radius: 5px;
  }

  .class-details {
    display: flex;
  }
  .search-btns {
    margin-left: 16.5px;
    background: var(--lemon-green);
    padding: 10px 25px;
    border-radius: 20px;
    margin-top: 15px;
    color: #ffffff;
  }
  .full-details-button {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 5px;
    border-radius: 5px;
  }

  .full-details-button:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
    padding: 5px;
    border-radius: 5px;
  }

  .ant-select-single .ant-select-selector {
    text-align: center;
  }

  .ant-select-single .ant-select-selector {
    text-align: left !important;
  }

  
}
