.author-wrapper{


.header-container{
  padding: 15px 0;
}

.first-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
}
.authors-cards-main-container {
  display: flex;
    flex-wrap: wrap;
    gap: 20px;

}
.book-total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.books {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 0px;
}

.second-item-container {
  margin-top: 0px;
}

.tab {
  display: flex;
  flex-direction: row;
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.tab-number-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  background-color: #172b4d;
  color: #ffffff;
  margin-left: 8px;
}

.bg-green {
  background-color: #55b035;
}

.bg-red {
  background-color: var(--dark-red);
}
.bg-brown {
  background-color: var(--brown);
}
.bg-neun {
  background-color: var(--neon-blue);
}

.drop-downs-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 70px;
  left: 63%;
}

.genre {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  margin-left: 16px;
}
.books-count-container {
  background-color: var(--dsh-green);
  width:30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
}
.books-count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
}
/*input-search styles*/
.ant-input-group .ant-input {
  // padding: 10px;
  // padding-left: 20px;
  // border-top-left-radius: 20px;
  // border-bottom-left-radius: 20px;
  // border: none;
  // font-family: "Poppins";
  // font-style: normal;
  // font-weight: 300;
  // font-size: 14px;
  // line-height: 15px;
  // color: #8e8e8e;
  // outline: none;
}
.ant-input-group-addon{
  background: var(--dark-blue);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
//   border: none;
//   // width: 100px;
//   border-top-right-radius: 20px;
//   border-bottom-right-radius: 20px;
//   background-color: white;
}

.anticon-search {

}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {

  border: none;
    background: none;
    color: #fff;

}

.author-books-container {
  padding: 40px;
  color: var(--black);
  p {
    font-size: 17px;
    font-weight: 600;
  }
  .author-books-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
  }
  .author-book-btn {
    background-color: var(--dark-blue);
    color: var(--white-font);
    margin-bottom: 20px;
  }
  .ant-card .ant-card-body {
    padding: 10px;
    text-align: center;
  }
}
}