.staff_assignment {

  .assignments {
 
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .assignment {
    display: flex;
    gap: 5px;
  }
  .dropdown-group{
    .header-text{
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
    }

  }
  .rightheader{
    display: flex;
    gap: 10px;
  }
  .data-unavailable {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  // .assignment-spin {
  //   margin-top: 100px;
  // }
  .class-dropdown {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }
  .section-dropdown {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }
  .no-data {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .subject-dropdown {
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .assignent_view {
    background-color: red;
    h3 {
      padding-bottom: 15px;
      font-size: 50px;
    }
  }

  .search-btns {
    margin-left: 16.5px;
    background: var(--lemon-green);
    padding: 10px 25px;
    border-radius: 20px;
    margin-top: 15px;
    color: #ffffff;
  }

  .ant-select-single .ant-select-selector {
    border-radius: 5px;
  }

 
  

  .ant-select-single .ant-select-selector {
    text-align: center;
  }

  .annouce-btnModal {
    background: var(--dark-blue);
    border-radius: 5px;
    height: 40px;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .annouce-btnModal:hover {
    background-color: var(--dark-blue);
    color: var(--white-font);
  }
}
